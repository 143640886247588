import React, { useState, useEffect, useContext } from "react";
import "./Task.css";
import { Col, Container, Row, Form } from "react-bootstrap";
import axios from "axios";
import AdminLayout from "../../hoc/AdminLayout";
import ClassTable from "../../components/ClassTable";
import { MyContext } from "../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileComponent from "../../components/ProfileComponent";

const Classes = () => {
    const navigate = useNavigate();

    const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }

    const [formData, setFromData] = useState({ companyID: companyID });
    const [classesInfo, setClassesInfo] = useState([]);
    const [newClasses, setNewClasses] = useState();

    // config

    const config = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFromData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === "subClassOf") {
            const data = JSON.parse(value)

            if (data) {
                setFromData((prevData) => ({
                    ...prevData,
                    subOf: data?._id,
                    subOfName: data?.class
                }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.post(
                "https://app.timetraker.com/backend/api/create-classes",
                formData,
                config
            );
            if (data.message === "hierarchyError") {
                hierarchyErrorNotify()
            }
            else if (data.message === "lengthError") {
                lengthErrorNotify()
            }
            else if (data) {
                setFromData({
                    companyID: formData.companyID,
                    className: "",
                    description: ""
                })
                document.getElementById("crateForm").reset()
                setNewClasses(data);
                TimesheetSubmitNotfy()
            }
        } catch (error) {
            console.log("error from create Class Api", error);
        }
    };

    const getAllClasses = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-classes/?companyID=${companyID}`,
                config
            );
            setClassesInfo(data.classes);
        } catch (error) {
            console.log("error from get Project api", error);
        }
    };



    const handleDataFromChild = (deletedProject) => {
        console.log("hello2");
        setNewClasses(deletedProject);
    };

    useEffect(() => {
        getAllClasses();
        if (!forPhoneScreenNoDisplay) {
            setSideBarStatus(false);
        }
    }, []);

    useEffect(() => {
        getAllClasses();
    }, [newClasses]);



    const TimesheetSubmitNotfy = () => {
        toast.success('Your Class has been added', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const hierarchyErrorNotify = () => {
        toast.error('You can not create more hierarchy', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const lengthErrorNotify = () => {
        toast.error('Class size must be between 0 and 100', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };



    return (
        <AdminLayout>
            <ToastContainer />
            <div
                className={sideBarStatus ? "content_right_dashboard_2" : "none"}
                fluid
                style={
                    (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                        ? { display: "none" }
                        : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
                }
            >
                <Container fluid className="dash3" style={{ padding: "0" }}>
                    {/* Row 1 */}
                    <Row>
                        <Col className="task-container">
                            <div
                                className="hamburgar"
                                style={
                                    sideBarStatus ? { display: "none" } : { display: "block" }
                                }
                            >
                                <i onClick={handleClick} className="fas fa-bars"></i>
                            </div>
                            <div className="task-top">
                                <div className="task-header1">
                                    <h5
                                        style={
                                            sideBarStatus == true
                                                ? { paddingLeft: "10px", paddingTop: "2px" }
                                                : { paddingLeft: "60px", paddingTop: "4px" }
                                        }
                                    >
                                        Classes
                                    </h5>
                                </div>

                                <div className="profile-header2">

                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ background: "#F1F1F1" }} className="mt-0">
                        <Col>
                            <div
                                className="time"
                                style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    marginLeft: "84px"
                                }}
                            >

                                <div className="text-time">
                                    <p
                                        style={{ cursor: "pointer", marginBottom: "0px" }}
                                        className="para1"
                                        onClick={() => {
                                            navigate("/project");
                                        }}
                                        
                                    >
                                        Projects
                                    </p>

                                    <p
                                        style={{ cursor: "pointer", marginBottom: "0px" }}
                                        className="para2"
                                        onClick={() => {
                                            navigate("/task");
                                        }}
                                    >
                                        Tasks
                                    </p>

                                    <p
                                        style={{ backgroundColor: "#64E48E", cursor: "pointer", marginBottom: "0px" }}
                                        className="para2"
                                    >
                                        Classes
                                    </p>

                                    <p
                                        style={{ cursor: "pointer", marginBottom: "0px" }}
                                        className="para1"
                                        onClick={() => {
                                            navigate("/assign");
                                        }}
                                    >
                                        Assign
                                    </p>

                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ background: "#F1F1F1" }}>
                        <Col md={12}>
                            <div className="adduser peoject_form_padding" style={{ marginBottom: "27px" }}>
                                <div className="adduser-header project-header">
                                    <button className="btn1">Class Info</button>
                                </div>


                                <form
                                    onSubmit={handleSubmit}
                                    action=""
                                    className="adduser-form"
                                    id="crateForm"
                                    style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}
                                >

                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start", width: "100%" }}>
                                            <div className="form1 ">
                                                <div style={{ marginBottom: "2rem" }} className=" projectInput">
                                                    <label className="lable_bold" htmlFor="">
                                                        Class Name
                                                        <i className="fa-sharp fa-light fa-asterisk"></i>
                                                    </label>
                                                    <input
                                                        required
                                                        value={formData.className}
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="className"
                                                        id=""
                                                    />
                                                </div>


                                                <div style={{ width: "100%", marginBottom: "2rem" }} className=" projectInput">
                                                    <label className="lable_bold" htmlFor="">
                                                        Description
                                                    </label>
                                                    <textarea
                                                        onChange={handleChange}
                                                        value={formData.description}
                                                        name="description"
                                                        className="project-textarea" style={{ width: "100%", marginLeft: "5px" }} />
                                                </div>


                                            </div>

                                            <div style={{ margin: "10px 0px" }} className="form2">



                                                <div style={{ marginBottom: "2rem" }} className="role-border projectInput">
                                                    <label className="lable_bold" htmlFor="">
                                                        Sub Class Of (optional)
                                                    </label>
                                                    <Form.Select
                                                        onChange={handleChange}
                                                        // value={formData.billable}
                                                        name="subClassOf"
                                                        className="project_select"
                                                        aria-label="Default select example"
                                                        style={{ marginLeft: "5px" }}
                                                    >
                                                        <option value="">Select</option>
                                                        {
                                                            classesInfo?.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={JSON.stringify(item)}>{item?.class}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>

                                                </div>



                                            </div>
                                        </div>




                                    </div>

                                    <div className="adduserBtn ">
                                        <button type="submit" className="btn5">
                                            Add Class
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </Col>
                    </Row>

     

                    {/* Row 3 */}
                    <Row style={{ background: "#F1F1F1" }}>
                        <Col>
                            <div className="table-container">
                                <ClassTable
                                    classesInfo={classesInfo}
                                    setClassesInfo={setClassesInfo}
                                    handleDataFromChild={handleDataFromChild}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </AdminLayout>
    );
};

export default Classes;
