import React, { useState, useEffect, useContext } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import "./TableDesign.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Form from "react-bootstrap/Form";
import Checkbox from '@mui/material/Checkbox';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { blue } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from "@mui/material";
import UserPdf from "../pages/admin/UserPdf";
import UserCsv from "../pages/admin/UserCsv";
import UserExcel from "../pages/admin/UserExcel";
import Swal from 'sweetalert2'
import { TableSortLabel } from "@mui/material";
import { MyContext } from "../context/MyProvider";

let columns = [
    { id: 'hours', label: 'Hours', minWidth: 60 },
    { id: 'date', label: 'Date', minWidth: 150 },
    { id: 'project', label: 'Project', minWidth: 120 },
    { id: 'task', label: 'Task', minWidth: 120 },
    { id: 'description', label: 'Description', minWidth: 150 },
    { id: 'billable', label: 'Billable', minWidth: 60 },
    { id: 'state', label: 'Status', minWidth: 100 },
    { id: 'check', label: 'Check', minWidth: 100 },
    { id: 'edit', label: 'Edit', minWidth: 80 },
];


// temporory data

const data = [
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },

];

export default function StickyHeadTable({ userTimesheetInfo, handleDataFromChild, setUserTimesheetInfo, selectedRowIds, setSelectedRowIds }) {

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
        var userID = adminloginInfo._id;
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
        var userID = userLoginInfo._id;
    }

    const [projectInfo, setProjectInfo] = useState([]);
    const [taskInfo, setTaskInfo] = useState([]);
    const [classesInfo, setClassesInfo] = useState([]);
    const [policy, setPolicy] = useState([]);
    const [selactedTask, setSelactedTask] = useState("")
    const [selactedClass, setSelactedClass] = useState("")
    const [selactedBillable, setSelactedBillable] = useState(true)
    const [selactedProject, setSelactedProject] = useState("")
    const [formData, setFormData] = useState({
        userID: userID,
        companyID: companyID,
        date: "",
        hours: "",
        project: "",
        task: "",
    });

    const { additionalSettings } = useContext(MyContext);

    if (additionalSettings.timesheetClasses === true) {
        columns = [
            { id: 'hours', label: 'Hours', minWidth: 60 },
            { id: 'date', label: 'Date', minWidth: 150 },
            { id: 'project', label: 'Project', minWidth: 120 },
            { id: 'task', label: 'Task', minWidth: 120 },
            { id: 'class', label: 'Class', minWidth: 120 },
            { id: 'description', label: 'Description', minWidth: 150 },
            { id: 'billable', label: 'Billable', minWidth: 60 },
            { id: 'state', label: 'Status', minWidth: 100 },
            { id: 'check', label: 'Check', minWidth: 100 },
            { id: 'edit', label: 'Edit', minWidth: 80 },
        ];
    }


    //config 

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [moreIconData, setMoreIconData] = useState()

    const handleOpenUserMenu = (event, item) => {
        setAnchorElUser(event.currentTarget);
        setMoreIconData(item)
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [anchorElUser1, setAnchorElUser1] = React.useState(null);
    const [rejectReason, setRejectReason] = useState("")

    const handleOpenUserMenu1 = (event, data) => {
        setAnchorElUser1(event.currentTarget);
        setRejectReason(data)
    };

    const handleCloseUserMenu1 = () => {
        setAnchorElUser1(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleInputChangeTask = (e) => {
        setSelactedTask(e.target.value)
    }

    const handleInputChangeClass = (e) => {
        setSelactedClass(e.target.value)
    }

    const handleInputChangebillable = (e) => {
        setSelactedBillable(e.target.value)
    }

    const handleInputChangeProject = (e) => {
        setSelactedProject(e.target.value)
    }


    const deleteData = async (itemID) => {
        handleCloseUserMenu()
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this Timesheet",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(`https://app.timetraker.com/backend/api/delete-timesheet/?timesheetID=${itemID}`, config)
                    console.log(data)
                    handleDataFromChild(data)
                    TimesheetDeleteNotfy()

                } catch (error) {
                    console.log(error)
                }
            }
        })

    }



    const submitTimesheet = async (itemID) => {
        handleCloseUserMenu()
        Swal.fire({
            title: "Are you sure?",
            text: "You want to submit this Timesheet",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, submit it!"
        }).then(async (result) => {
            if (result.isConfirmed) {


                try {
                    const { data } = await axios.get(`https://app.timetraker.com/backend/api/submit-timesheet/?timesheetID=${itemID}`, config)
                    console.log(data)
                    handleDataFromChild(data)
                    TimesheetSubmitNotfy()

                }
                catch (error) {
                    console.log("submitTimesheet error", error)
                }
            }
        });
    }

    const [reversSort, setReversSort] = useState(true)
    const [sortedColumn, setSortedColumn] = useState(null);


    const handleChangeSort = (field) => {
        const sortedData = [...userTimesheetInfo].sort((a, b) => {

            let aValue = a[field];
            let bValue = b[field];

            if (field === 'class') {
                aValue = a?.classDetails?.class ? a?.classDetails?.class : "";
                bValue = b?.classDetails?.class ? b?.classDetails?.class : "";
            }

            let comparison = 0;
            if (field === 'date') {
                comparison = new Date(aValue) - new Date(bValue);
            } else if (typeof aValue === 'string') {
                comparison = aValue.localeCompare(bValue);
            } else if (typeof aValue === 'number') {
                comparison = aValue - bValue;
            } else if (typeof aValue === 'boolean') {
                // Handle boolean fields
                comparison = (aValue === bValue) ? 0 : (aValue ? 1 : -1);
            }

            return reversSort ? comparison : -comparison;
        });

        setUserTimesheetInfo(sortedData);
        setReversSort(prevToggle => !prevToggle);
        setSortedColumn(field)
    };

    // useEffect(() => {
    //     if (reversSort) {
    //         const data = [...userTimesheetInfo].sort((a, b) => new Date(a.date) - new Date(b.date));
    //         setUserTimesheetInfo(data)
    //     } else {
    //         const data = [...userTimesheetInfo].sort((a, b) => new Date(b.date) - new Date(a.date));
    //         setUserTimesheetInfo(data)
    //     }
    // }, [reversSort])

    const handleCheck = (_id) => {
        setSelectedRowIds(prevSelectedRowIds => {
            if (prevSelectedRowIds.includes(_id)) {
                return prevSelectedRowIds.filter(id => id !== _id);
            } else {
                return [...prevSelectedRowIds, _id];
            }
        });
    };



    const [allCheck, setAllCheck] = useState(false)

    const handleChnageAllCheck = () => {
        if (allCheck === false) {
            setAllCheck(true)
            if (rowsPerPage > 0) {
                userTimesheetInfo?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {

                    if (item?.state === 'hold') {
                        setSelectedRowIds(prevSelectedRowIds => {
                            return [...prevSelectedRowIds, item?._id];
                        })
                    }
                })
            }
        } else {
            setAllCheck(false)
            setSelectedRowIds([])
        }
    }


    const TimesheetSubmitNotfy = () => {
        toast.success('Your timesheet has been submited', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const TimesheetDeleteNotfy = () => {
        toast.success('Your timesheet has been deleted', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };






    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };




    const [dataNeedToEdit, setDataNeedToEdit] = useState()

    const handleDailogBoxOpen = async (_id, hours, date, project, task, description, billable, timeOff, classDetails) => {
        setDataNeedToEdit({
            _id: _id,
            description: description,
            task: task,
            project: project,
            date: date,
            hours: hours,
            timeOff: timeOff,
            classDetails: classDetails
        })
        setFormData({
            description: description,
            task: task,
            project: project,
            date: date,
            hours: hours,
            timeOff: timeOff,
            classDetails: classDetails
        })
        handleCloseUserMenu()
        setSelactedTask(task)
        setSelactedClass(classDetails?._id)
        setSelactedProject(project)
        setSelactedBillable(billable)
        handleClickOpen()
    }






    // get all project

    const getAllProject = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/user-project/?userID=${userID}`,
                config
            );
            setProjectInfo(data.projects);
        } catch (error) {
            console.log("error", error)
        }
    };


    // get all task

    const getAllTask = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-task/?companyID=${companyID}`,
                config
            );
            setTaskInfo(data.tasks);
        } catch (error) {
            console.log("error", error)
        }
    };

    const getAllClasses = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-active-classes/?companyID=${companyID}`,
                config
            );
            setClassesInfo(data.classes);
        } catch (error) {
            console.log('error', error)
        }
    };

    const getUserPolciy = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-policy-for-users/?userID=${userID}`,
                config
            );
            if (data) {
                setPolicy(data.allPolicy)
            }
        } catch (error) {
            console.log("error from get user timesheet api ", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("formData", formData)
        if (formData.task == "" || formData.project == "" || formData.hours == "" || formData.date == "") {
            alert("all the feilds required");
        } else {
            postTimesheetData();
            // console.log("formData",formData)
        }
    };

    const postTimesheetData = async () => {
        try {
            const classData = classesInfo?.find(item => item._id === selactedClass)
            const { data } = await axios.post(
                `https://app.timetraker.com/backend/api/edit-timesheet`,
                {
                    date: formData.date,
                    task: selactedTask,
                    project: selactedProject,
                    description: formData.description,
                    hours: formData.hours,
                    _id: dataNeedToEdit._id,
                    billable: selactedBillable,
                    classDetails: {
                        _id: classData?._id,
                        class: classData?.class,
                    }
                },
                config
            );
            handleClose();
            handleCloseUserMenu()
            if (data) {
                handleDataFromChild(data)
                alert("Your timesheet has been successfully updated.");
            }
        } catch (error) {
            console.log("error from post timesheet api", error);
        }
    };

    useEffect(() => {
        getAllTask();
        getAllProject();
        getAllClasses()
        getUserPolciy()

    }, []);

    const [open2, setOpen2] = React.useState(false);
    const [selectedPrint, setSelectedPrint] = useState([])


    const handleClickOpen2 = (item) => {
        handleCloseUserMenu()
        setOpen2(true);
        setSelectedPrint([item])
    };

    const handleClose2 = () => {
        setOpen2(false);
    };



    return (
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <TableContainer className="table_overflow">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >
                        <TableRow >
                            {columns.map((column) => (

                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, background: '#04542C', color: '#fff', textAlign: 'center' }}>
                                    {column.label === "Check" ?
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {column.label}
                                            <Checkbox
                                                sx={{
                                                    color: '#76ff03',
                                                    '&.Mui-checked': {
                                                        color: '#76ff03',
                                                    },
                                                }}
                                                onChange={handleChnageAllCheck}
                                            />
                                        </div>
                                        : column.label === "Edit" ? <div> {column.label} </div> :
                                            <div
                                                onClick={() => handleChangeSort(column.id)}
                                                style={{ display: "flex", cursor: "pointer" }}>
                                                <ArrowDropDownCircleIcon
                                                    style={{
                                                        transform: column.id === sortedColumn && !reversSort ? "rotate(180deg)" : "rotate(0deg)",
                                                        margin: "0px 10px 0px 17px",
                                                    }}
                                                />
                                                {column.label}
                                            </div>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {userTimesheetInfo
                            ? (rowsPerPage > 0 ? userTimesheetInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : userTimesheetInfo)?.map((item, index) => {
                                function shortenString(str) {
                                    if (str?.length > 8) {
                                        return str.substring(0, 8) + '...';
                                    }
                                    return str;
                                }

                                return (

                                    <TableRow style={item.state == 'rejected' ? { background: "#ffb2b2b5" } : { background: "white" }} hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell align="center">{item?.hours}</TableCell>
                                        <TableCell align="center">{item?.date}</TableCell>
                                        <TableCell title={item?.project} align="center">{shortenString(item?.project ? item?.project : "Empty")}</TableCell>
                                        <TableCell title={item?.task} align="center">{shortenString(item?.task)}</TableCell>
                                        <TableCell style={!additionalSettings.timesheetClasses ? { display: "none" } : null} title={item?.classDetails?.class} align="center">{shortenString(item?.classDetails?.class ? item?.classDetails?.class : "Empty")}</TableCell>
                                        <TableCell title={item?.description ? item?.description : "Empty"} align="center">{shortenString(item?.description ? item?.description : "Empty")}</TableCell>
                                        <TableCell align="center">{item?.billable ? "Yes" : "No"}</TableCell>
                                        <TableCell align="center">{item?.state === 'rejected' ? <div style={{ display: "flex", alignItems: "center", gap: '5px' }}>{item?.state} <InfoIcon onClick={(event) => handleOpenUserMenu1(event, item.rejectReason)} /></div> : item?.state}</TableCell>
                                        <Menu
                                            sx={{ mt: '30px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser1}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser1)}
                                            onClose={handleCloseUserMenu1}
                                        >
                                            <MenuItem style={{ gap: "10px", alignItems: "start", background: "white" }} >
                                                <Typography>{rejectReason}</Typography>
                                            </MenuItem>
                                        </Menu>
                                        <TableCell align="center"><Checkbox checked={selectedRowIds.includes(item._id)}
                                            onChange={() => handleCheck(item._id)}

                                            sx={{
                                                color: blue[800],
                                                '&.Mui-checked': {
                                                    color: blue[600],
                                                },
                                            }} /></TableCell>

                                        <TableCell align="center">
                                            <i
                                                title={item?.state === 'hold' || item?.state === 'rejected' ? "Edit" : "null"}
                                                onClick={() =>
                                                    item?.state === 'hold' || item?.state === 'rejected'
                                                        ? handleDailogBoxOpen(
                                                            item?._id,
                                                            item?.hours,
                                                            item?.date,
                                                            item?.project,
                                                            item?.task,
                                                            item?.description,
                                                            item?.billable,
                                                            item?.timeOff,
                                                            item?.classDetails,
                                                        )
                                                        : console.log('hello')
                                                }
                                                style={
                                                    item?.state === 'hold' || item?.state === 'rejected'
                                                        ? { color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }
                                                        : { color: 'gray', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }
                                                }
                                                className="fa-solid fa-pen-to-square userIconsHover"
                                            ></i>
                                        </TableCell>

                                        {/* <TableCell align="center"> <i title={item?.state === 'hold' ? "submit" : "null"} onClick={() => (item?.state == 'hold' ? submitTimesheet(item?._id) : console.log(""))} style={item?.state == 'hold' ? { color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' } : { color: 'gray', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }} class="fa-solid fa-arrow-up-from-bracket userIconsHover "></i></TableCell>
                                        <TableCell align="center"><i title={item?.state === 'hold' ? "edit" : "null"} onClick={() => (item?.state == 'hold' ? handleDailogBoxOpen
                                            (item?._id, item?.hours, item?.date, item?.project, item?.task, item?.description, item?.billable) : console.log('hello'))} style={item?.state == 'hold' ? { color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' } : { color: 'gray', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }} class="fa-solid fa-pen-to-square userIconsHover"></i></TableCell>
                                        <TableCell align="center"> <i title={item?.state === 'hold' ? "delete" : "null"} onClick={() => (item?.state == 'hold' ? deleteData(item?._id) : console.log('you cant delete this timesheet'))} style={item?.state == 'hold' ? { color: 'red', fontSize: '20px', cursor: 'pointer' } : { color: 'gray', fontSize: '20px', cursor: 'pointer' }} className="fa-sharp fa-solid fa-trash userIconsHover "></i></TableCell> */}
                                    </TableRow>
                                )


                            })
                            : data?.map((item, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell align="center">{item?.hours}</TableCell>
                                        <TableCell align="center">{item?.date}</TableCell>
                                        <TableCell align="center">{item?.project}</TableCell>
                                        <TableCell align="center">{item?.task}</TableCell>
                                        <TableCell align="center">{item?.state}</TableCell>
                                        <TableCell align="center">{item?.createdAt}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={userTimesheetInfo?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />




            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div
                    className="dialog-main"
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        <DialogActions>
                            <Button
                                style={{

                                    background: "rgb(100, 228, 142)",
                                    borderRadius: "10px",
                                    color: "#000",
                                    fontWeight: "600",
                                    padding: "5px 15px",
                                }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </div>

                    <form onSubmit={handleSubmit} className="border-around">
                        <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "flex" }} className="form-time1 ">
                            <div style={{ width: "50%", display: 'flex', flexDirection: 'column', gap: '5px' }} className="customer">
                                <label className="lable_bold" htmlFor="">
                                    Hours
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <input
                                    style={formData?.timeOff === true ? { height: '40px', background: "#e8ebee" } : { height: '40px' }}
                                    name="hours"
                                    required
                                    disabled={formData?.timeOff === true ? true : false}
                                    defaultValue={dataNeedToEdit?.hours}
                                    onChange={handleInputChange}
                                    type="number"
                                />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: "50%" }} className="task">
                                <label className="lable_bold" htmlFor="">

                                    Date
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <input
                                    style={{ fontFamily: 'Inter', fontSize: '15px', height: '40px' }}
                                    defaultValue={dataNeedToEdit?.date}
                                    name="date"
                                    type="date"
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "flex" }} className="form-time1 ">
                            <div style={{ width: "50%" }} className="customer">
                                <label className="lable_bold" htmlFor="">
                                    Customer:Project
                                </label>
                                <Form.Select
                                    style={{ cursor: "pointer" }}
                                    value={selactedProject}
                                    className="area"
                                    name="project"
                                    aria-label="Default select example"
                                    onChange={handleInputChangeProject}
                                    disabled={formData?.timeOff === true ? true : false}
                                >
                                    <option value="">Select</option>
                                    {projectInfo &&
                                        projectInfo.map((item, index) => {
                                            return (
                                                <option key={index} value={item.project}>
                                                    {item.project}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </div>

                            <div style={{ width: "50%" }} className="task">
                                <label className="lable_bold" htmlFor="">
                                    Task
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <Form.Select
                                    style={{ cursor: "pointer" }}
                                    value={selactedTask}
                                    className="area"
                                    name="task"
                                    aria-label="Default select example"
                                    required
                                    onChange={handleInputChangeTask}
                                >
                                    <option value="">Select</option>
                                    {formData?.timeOff === true ? policy &&
                                        policy.map((item, index) => {
                                            return (
                                                <option key={index} value={item?.policyDetails?.policyName}>
                                                    {item?.policyDetails?.policyName}
                                                </option>
                                            );
                                        }) :
                                        taskInfo.map((item, index) => {
                                            return (
                                                <option key={index} value={item.name}>
                                                    {item.task}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </div>


                        </div>







                        <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "flex" }} className="form-time1 ">


                            <div style={additionalSettings.timesheetClasses === true ? { width: "50%" } : { display: "none" }} className="task">
                                <label className="lable_bold" htmlFor="">
                                    Class
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <Form.Select
                                    style={{ cursor: "pointer" }}
                                    value={selactedClass}
                                    className="area"
                                    name="classDetails"
                                    aria-label="Default select example"
                                    required={additionalSettings?.timesheetClasses ? additionalSettings?.timesheetClasses : false}
                                    onChange={handleInputChangeClass}
                                >
                                    <option value="">Select</option>

                                    {classesInfo &&
                                        classesInfo.map((item, index) => {
                                            return (
                                                <option key={index} value={item._id}>
                                                    {item.class}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </div>


                            <div style={additionalSettings.timesheetClasses === true ? { display: "none" } : { width: "50%", marginTop: "3px" }} className="customer">
                                <label className="lable_bold" htmlFor="">
                                    Description
                                </label>
                                <textarea defaultValue={formData?.description ? formData?.description : ""} className="edit_textarea" onChange={handleInputChange} type="text" name="description" id="">
                                </textarea>
                            </div>

                            <div style={{ width: "50%" }} className="task">
                                <label className="lable_bold" htmlFor="">
                                    Billable
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <Form.Select
                                    style={{ cursor: "pointer" }}
                                    value={selactedBillable}
                                    className="area"
                                    name="billable"
                                    aria-label="Default select example"
                                    required
                                    onChange={handleInputChangebillable}
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </Form.Select>
                            </div>
                        </div>


                        <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "none" }} className="form-time1 ">
                            <div style={{ width: "100%", marginTop: "3px" }} className="customer">
                                <label className="lable_bold" htmlFor="">
                                    Description
                                </label>
                                <textarea defaultValue={formData?.description ? formData?.description : ""} className="edit_textarea" onChange={handleInputChange} type="text" name="description" id="">
                                </textarea>
                            </div>
                        </div>


                        <div className="strong">
                            <button type="submit" style={{ border: "none", background: "white" }}>
                                <strong style={{ display: "flex" }}>
                                    Save
                                </strong>
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>

            <Dialog
                open={open2}
                keepMounted
                onClose={handleClose2}
                aria-describedby="alert-dialog-slide-description"
            >
                <div style={{ padding: "50px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h4 style={{ marginBottom: "40px" }}>Please choose the format</h4>
                    <div style={{ marginBottom: "40px" }} className="button-container">
                        <UserPdf userTimesheetInfo={selectedPrint} />
                        <UserExcel userTimesheetInfo={selectedPrint} />
                        <UserCsv userTimesheetInfo={selectedPrint} />
                    </div>
                    <div className="button-container">
                        <button className='button-container-button' onClick={handleClose2} style={{ cursor: "pointer", background: "rgb(100, 228, 142)", }}>Close</button>
                    </div>
                </div>
            </Dialog>
        </Paper>
    );
}
