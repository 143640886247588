import React, { useContext } from "react";
import "./AddUser.css";
import { Col, Form, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import signupImg1 from "../../img/Vector.png";
import signupImg2 from "../../img/user2.png";
import { useEffect, useState } from "react";
import AllUsersTable from "../../components/AllUsersTable.js";
import axios from "axios";
import AdminLayout from "../../hoc/AdminLayout";
import { MyContext } from "../../context/MyProvider";
import { ToastContainer, toast } from 'react-toastify';
import ChatAfterLogin from "../../components/chat/ChatAfterLogin";
import ProfileComponent from "../../components/ProfileComponent";
import UserTimeOffDetails from "./UserAdditionalDetails/TimeOffDetails/UserTimeOffDetails.js";
import UserHolidayDetails from "./UserAdditionalDetails/HolidayDetails/UserHolidayDetails.js";
import UserAssignedProjectDetails from "./UserAdditionalDetails/AssignProject/UserAssignedProjectDetails.js";
import Loder from "../../components/Loder.js";

const AddUser = () => {
  const navigate = useNavigate();
  const { loading, setLoading, sideBarStatus, setSideBarStatus, activTab, setActivTab, newUser, setNewUser, additionalSettings } = useContext(MyContext);

  const handleClick = () => {
    if (sideBarStatus == true) {
      setSideBarStatus(false);
    } else {
      setSideBarStatus(true);
    }
  };

  const screenWidth = window.innerWidth;
  if (screenWidth >= 840) {
    var forPhoneScreenNoDisplay = true;
  } else {
    var forPhoneScreenNoDisplay = false;
  }

  const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));


  if (adminloginInfo) {
    var companyID = adminloginInfo?._id;
    var token = adminloginInfo?.token
  } else if (userLoginInfo) {
    var companyID = userLoginInfo?.companyID;
    var token = userLoginInfo?.token
  }


  const [allemployee, setAllemployee] = useState([]);
  const [next, setNext] = useState(false);
  const [teamInfo, setTeamInfo] = useState([]);
  // const [activTab, setActivTab] = useState("policy");

  console.log("allemployee", allemployee)


  const [formDataUser, setFromDataUser] = useState({
    companyID: companyID,
    email: "",
    password: "",
  });

  // config

  const config = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    setFromDataUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "approver") {
      const data = allemployee.filter((item) => item._id === value)
      setFromDataUser((prevData) => ({
        ...prevData,
        approverName: data?.[0]?.name,
        approverID: data?.[0]?._id,
      }));
    }
  };



  const isPasswordValid = (password) => {
    // The regular expression to enforce the criteria
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

    // Check if the password matches the criteria
    return passwordRegex.test(password);
  };


  const getAllTeam = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-team/?companyID=${companyID}`,
        config
      );
      setTeamInfo(data);
    } catch (error) {
      console.log("error from team api", error);
    }
  };

  const handleSubmitUser = async (e, next) => {
    setLoading(true)
    setNext(next)
    e.preventDefault();
    const validatePass = isPasswordValid(formDataUser.password)

    if (validatePass == true) {
      if (formDataUser.role == "sub-admin") {
        try {
          const { data } = await axios.post(
            "https://app.timetraker.com/backend/api/sub-admin-register",
            formDataUser,
            config
          );
          if (data?.message == "email exist") {
            setLoading(false)
            alert("Email already exist, Please try with another email address")
          }
          else if (data?.message == "name exist") {
            setLoading(false)
            alert("name already exist, Please chnage the name")
          } else {
            setLoading(false)
            adminAddNotfy()

            setFromDataUser({
              companyID: formDataUser.companyID,
              name: "",
              email: "",
              phone: "",
              password: "",
              role: "",
              hireDate: "",
              basePayRate: 0,
              billRate: 0
            })
            setNewUser(data);
            document.getElementById("crateForm").reset()

          }
        } catch (error) {
          setLoading(false)
          servererrorMessage()
          console.log("add user api", error);
        }
      } else if (formDataUser.role == "manager") {
        try {
          const { data } = await axios.post(
            "https://app.timetraker.com/backend/api/manager-register",
            formDataUser,
            config
          );
          if (data?.message == "email exist") {
            setLoading(false)
            alert("Email already exist, Please try with another email address")
          }
          else if (data?.message == "name exist") {
            setLoading(false)
            alert("name already exist, Please chnage the name")
          } else {
            setLoading(false)
            managerAddNotfy()

            setFromDataUser({
              companyID: formDataUser.companyID,
              name: "",
              email: "",
              phone: "",
              password: "",
              role: "",
              hireDate: ""
            })
            setNewUser(data);
            document.getElementById("crateForm").reset()

          }
        } catch (error) {
          setLoading(false)
          servererrorMessage()
          console.log("add user api", error);
        }
      } else if (formDataUser.role == "user") {
        try {
          const { data } = await axios.post(
            "https://app.timetraker.com/backend/api/user-register",
            formDataUser,
            config
          );
          if (data?.message == "email exist") {
            setLoading(false)
            alert("Email already exist, Please try with another email address")
          }
          else if (data?.invalidPass) {
            setLoading(false)
            alert("Password must have at least 1 uppercase, 1 lowercase, and 1 number")
          }
          else if (data?.message == "name exist") {
            setLoading(false)
            alert("name already exist, Please chnage the name")
          } else {
            setLoading(false)
            userAddNotfy()
            setFromDataUser({
              companyID: formDataUser.companyID,
              name: "",
              email: "",
              phone: "",
              password: "",
              role: "",
              hireDate: ""
            })
            setNewUser(data);
            document.getElementById("crateForm").reset()

          }
        } catch (error) {
          setLoading(false)
          servererrorMessage()
          console.log("add user api", error);
        }

      }

    } else {
      alert("Password must have at least 1 uppercase, 1 lowercase, and 1 number")
    }

  };

  const getAllUser = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-users/?companyID=${companyID}`,
        config
      );
      return data.allUsers;
    } catch (error) {
      console.log('error from get all user api', error);
      return [];
    }
  };

  const getAllManager = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-manager/?companyID=${companyID}`,
        config
      );
      return data.allManager;
    } catch (error) {
      console.log('error from get all manager api', error);
      return [];
    }
  };

  const fetchData = async () => {
    const [users, managers] = await Promise.all([getAllUser(), getAllManager()]);
    setAllemployee([...users, ...managers]);
  };



  useEffect(() => {
    fetchData()
    getAllTeam()
    if (!forPhoneScreenNoDisplay) {
      setSideBarStatus(false);
    }
  }, []);

  useEffect(() => {
    console.log("newUser1", newUser)
    if (next && newUser?._id) {
      setActivTab("policy")
    }
  }, [newUser]);



  const adminAddNotfy = () => {
    toast.success('Admin has been added', {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };


  const managerAddNotfy = () => {
    toast.success('Manager has been added', {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };


  const userAddNotfy = () => {
    toast.success('User has been added', {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const errorNotfy = (message) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const naviationErrorMessage = () => {
    toast.error("Kindly add a user first to proceed!!", {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const servererrorMessage = () => {
    toast.error("Something went wrong please try again!!", {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <AdminLayout>
      < ToastContainer />
      {
        loading ?
          <Loder className='loder' />
          :
          null
      }
      <div
        className={sideBarStatus ? "content_right_dashboard" : "none"}
        fluid
        style={
          (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
            ? { display: "none" }
            : loading  ?
            { filter: 'blur(2px)', display: "block", minHeight: "100vh", background: "#f1f1f1" }
            : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
        }
      >
        <div
          className="hamburgar"
          style={sideBarStatus ? { display: "none" } : { display: "block" }}
        >
          <i onClick={handleClick} className="fas fa-bars"></i>
        </div>
        <Container fluid className="dash3" style={{ padding: "0" }}>
          {/* Row 1 */}
          <Row>
            <Col md={12} className="adduser-container">
              <div className="adduser-top">
                <div className="adduser-header1">
                  <h5
                    style={
                      sideBarStatus == true
                        ? { paddingLeft: "10px", paddingTop: "2px" }
                        : { paddingLeft: "60px", paddingTop: "4px" }
                    }
                  >
                    Add Users
                  </h5>
                </div>

                <div className="adduser-header2">
                  <ProfileComponent />
                </div>
              </div>
            </Col>
          </Row>

          {/* Row 2 */}
          <Row style={activTab === "user" ? { background: "#F1F1F1" } : { display: "none" }}>
            <Col md={12}>
              <div className="adduser">
                <div className="adduser-header">
                  <button className="btn1">User Info</button>
                  <button onClick={() => naviationErrorMessage()} style={{ background: "white" }} className="btn1">Time Off Info</button>
                  <button onClick={() => naviationErrorMessage()} style={{ background: "white" }} className="btn1">Holiday Info</button>
                </div>

                <form
                  action=""
                  className="adduser-form add_user_page_form"
                  id="crateForm"
                  autocomplete="off"
                >
                  <div className="form1">
                    <div className="add_user_input_margin_bottom">
                      <label className="lable_bold" htmlFor="">
                        Name
                        <i className="fa-sharp fa-light fa-asterisk"></i>
                      </label>
                      <input
                        required
                        onChange={handleChangeUser}
                        type="text"
                        name="name"
                        id=""
                        autocomplete="off"
                        value={formDataUser.name}
                      />
                    </div>
                    <div className="add_user_input_margin_bottom">
                      <label className="lable_bold" htmlFor="">
                        Email
                        <i className="fa-sharp fa-light fa-asterisk"></i>
                      </label>
                      <input
                        required
                        onChange={handleChangeUser}
                        autocomplete="off"
                        type="email"
                        name="email"
                        id="email_for_user"
                        className="amount"
                        value={formDataUser.email}
                      />
                    </div>
                    <div className="add_user_input_margin_bottom">
                      <label className="lable_bold" htmlFor="">
                        Phone
                      </label>
                      <input
                        onChange={handleChangeUser}
                        autocomplete="off"

                        type="number"
                        name="phone"
                        id=""
                        value={formDataUser.phone}
                      />
                    </div>
                    <div className="add_user_input_margin_bottom">
                      <label className="lable_bold" htmlFor="">
                        Hire Date
                        <i className="fa-sharp fa-light fa-asterisk"></i>
                      </label>
                      <input
                        required
                        onChange={handleChangeUser}

                        autocomplete="off"
                        type="date"
                        name="hireDate"
                        id=""
                        value={formDataUser?.hireDate}
                      />
                    </div>


                    <div className="role-border add_user_input_margin_bottom">
                      <label className="lable_bold" htmlFor="">
                        Team
                      </label>
                      <Form.Select
                        
                        onChange={handleChangeUser}
                        autocomplete="off"
                        name="team"
                        className="role"
                        aria-label="Default select example"
                        value={formDataUser.team}
                        style={{ marginLeft: "0px" }}
                      >
                        <option value="">Select</option>
                        {teamInfo?.map((item, index) => {
                          return (
                            <option key={index} value={item?._id}>{item?.name}</option>
                          )
                        })}
                      </Form.Select>


                    </div>



                  </div>

                  <div className="form2">
                    <div className="add_user_input_margin_bottom">
                      <label className="lable_bold" htmlFor="">
                        Password
                        <i className="fa-sharp fa-light fa-asterisk"></i>
                      </label>
                      <input
                        required
                        onChange={handleChangeUser}
                        autocomplete="off"
                        type="password"
                        name="password"
                        id="pass_for_user"
                        value={formDataUser.password}
                      />
                    </div>
                    <div className="role-border add_user_input_margin_bottom">
                      <label className="lable_bold" htmlFor="">
                        Role
                        <i className="fa-sharp fa-light fa-asterisk"></i>
                      </label>
                      <Form.Select
                        required
                        onChange={handleChangeUser}
                        autocomplete="off"
                        name="role"
                        className="role"
                        aria-label="Default select example"
                        value={formDataUser.role}
                      >
                        <option value="">Select</option>
                        <option value="sub-admin">Admin</option>
                        <option value="manager">Manager</option>
                        <option value="user">User</option>
                      </Form.Select>


                    </div>

                    <div title="Cost Rate / Hours" className="add_user_input_margin_bottom company_settings_input">
                      <label className="lable_bold" htmlFor="">
                        Cost Rate ($)
                        <i className="fa-sharp fa-light fa-asterisk"></i>
                      </label>
                      <input
                        required
                        onChange={handleChangeUser}

                        autocomplete="off"
                        type="number"
                        name="basePayRate"
                        id=""
                        value={formDataUser?.basePayRate}
                      />
                    </div>

                    <div title="Cost Rate / Hours" className="add_user_input_margin_bottom company_settings_input">
                      <label className="lable_bold" htmlFor="">
                        Bill Rate ($)
                      </label>
                      <input
          
                        onChange={handleChangeUser}

                        autocomplete="off"
                        type="number"
                        name="billRate"
                        id=""
                        value={formDataUser?.billRate}
                      />
                    </div>

                    <div style={additionalSettings.expenseApprover === false && additionalSettings.timesheetApprover === false ? { display: "none" } : null} className="role-border add_user_input_margin_bottom">
                      <label className="lable_bold" htmlFor="">
                        Approver
                      </label>
                      <Form.Select
                        onChange={handleChangeUser}
                        autocomplete="off"
                        name="approver"
                        style={{ marginLeft: "0px" }}
                        className="role"
                        aria-label="Default select example"
                        value={formDataUser.approver}
                        disabled={formDataUser.role === "sub-admin" ? true : false}

                      >
                        <option value="">Select</option>
                        {allemployee?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>{item?.name}</option>
                          )
                        })}

                      </Form.Select>


                    </div>


                  </div>

                </form>
                <div className="adduserBtn ">
                  <button onClick={(e) => handleSubmitUser(e, false)} className="btn5">
                    Save
                  </button>
                  <button onClick={(e) => handleSubmitUser(e, true)} className="btn5">
                    Save & Next
                  </button>
                </div>
              </div>
            </Col>
          </Row>

          <div style={activTab === "policy" ? { display: "block" } : { display: "none" }}>
            {newUser && <UserTimeOffDetails newUser={newUser} />}
          </div>

          <div style={activTab === "holiday" ? { display: "block" } : { display: "none" }}>
            {newUser && <UserHolidayDetails newUser={newUser} />}
          </div>


        </Container>
      </div>

    </AdminLayout>
  );
};

export default AddUser;
