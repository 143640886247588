import React, { useEffect, useState, useContext } from "react";
import "../../pages/admin/TimesheetAprove.css";
import axios from "axios";
import { Col, Container, Row, Form } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menu from '@mui/material/Menu';

// import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { MyContext } from "../../context/MyProvider";
import Swal from "sweetalert2";
import Checkbox from '@mui/material/Checkbox';
import { blue } from '@mui/material/colors';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import CryptoJS from 'crypto-js';
import Loder from "../Loder";
import LoderForTimesheetDownload from "../LoderForTimesheetDownload";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import UserPdf from "../../pages/admin/UserPdf";
import UserExcel from "../../pages/admin/UserExcel";
import UserCsv from "../../pages/admin/UserCsv";
import MapComponent from "../map/MapComponent";
import LocationOnIcon from '@mui/icons-material/LocationOn';



let columnsForAllSubmitedTimesheetTable = [
    { id: "date", label: "Date", minWidth: 150 },
    { id: "name", label: "Name", minWidth: 100 },
    { id: "hours", label: "Hours", minWidth: 50 },
    { id: "Billable", label: "Billable", minWidth: 100 },
    { id: "task", label: "Task", minWidth: 120 },
    { id: "project", label: "Project", minWidth: 120 },
    { id: "Description", label: "Description", minWidth: 170 },
    { id: "check", label: "Check", minWidth: 100, align: "center" },
    { id: "action", label: "Action", minWidth: 120, align: "center" },
    {
        id: "status",
        label: "Status",
        minWidth: 100,
        align: "center",
    },
];



let columnsForAllSubmitedTimesheetTableWithLocation = [
    { id: "date", label: "Date", minWidth: 150 },
    { id: "name", label: "Name", minWidth: 100 },
    { id: "hours", label: "Hours", minWidth: 50 },
    { id: "Billable", label: "Billable", minWidth: 100 },
    { id: "task", label: "Task", minWidth: 120 },
    { id: "project", label: "Project", minWidth: 120 },
    { id: "Description", label: "Description", minWidth: 170 },
    { id: "check", label: "Check", minWidth: 100, align: "center" },
    { id: "action", label: "Action", minWidth: 120, align: "center" },
    {
        id: "status",
        label: "Status",
        minWidth: 100,
        align: "center",
    },
    { id: "location", label: "Location", minWidth: 250, align: "center" }
];


// temporory data

const data = [
    {
        task: "Development",
        status: "Active",
        createdAt: "2023-06-07T04:12:17.986Z",
    },
    {
        task: "Development",
        status: "Active",
        createdAt: "2023-06-07T04:12:17.986Z",
    },
    {
        task: "Development",
        status: "Active",
        createdAt: "2023-06-07T04:12:17.986Z",
    },
];


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TimesheetAndTimeOffApprove = () => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));

    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var userID = adminloginInfo?._id;
        var token = adminloginInfo?.token
        var TimesheetLocationTrack = adminloginInfo.accessibility.TimesheetLocationTrack;
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var userID = userLoginInfo?._id;
        var token = userLoginInfo?.token
        var TimesheetLocationTrack = userLoginInfo.accessibility.TimesheetLocationTrack;
    }

    const { loading, setLoading, loadingTimesheet, setLoadingTimesheet, additionalSettings } = useContext(MyContext);


    if (additionalSettings.timesheetClasses === true) {

        columnsForAllSubmitedTimesheetTable = [
            { id: "date", label: "Date", minWidth: 150 },
            { id: "name", label: "Name", minWidth: 100 },
            { id: "hours", label: "Hours", minWidth: 50 },
            { id: "Billable", label: "Billable", minWidth: 100 },
            { id: "task", label: "Task", minWidth: 120 },
            { id: "project", label: "Project", minWidth: 120 },
            { id: 'class', label: 'Class', minWidth: 120 },
            { id: "Description", label: "Description", minWidth: 170 },
            { id: "check", label: "Check", minWidth: 100, align: "center" },
            { id: "action", label: "Action", minWidth: 120, align: "center" },
            {
                id: "status",
                label: "Status",
                minWidth: 100,
                align: "center",
            },
        ];

        columnsForAllSubmitedTimesheetTableWithLocation = [
            { id: "date", label: "Date", minWidth: 150 },
            { id: "name", label: "Name", minWidth: 100 },
            { id: "hours", label: "Hours", minWidth: 50 },
            { id: "Billable", label: "Billable", minWidth: 100 },
            { id: "task", label: "Task", minWidth: 120 },
            { id: "project", label: "Project", minWidth: 120 },
            { id: 'class', label: 'Class', minWidth: 120 },
            { id: "Description", label: "Description", minWidth: 170 },
            { id: "check", label: "Check", minWidth: 100, align: "center" },
            { id: "action", label: "Action", minWidth: 120, align: "center" },
            {
                id: "status",
                label: "Status",
                minWidth: 100,
                align: "center",
            },
            { id: "location", label: "Location", minWidth: 250, align: "center" }
        ];

    }
    //     // config


    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };



    const [newData, setNewData] = useState();



    const [allsubmitedTimesheet, setAllsubmitedTimesheet] = useState([])
    const [allsubmitedTimesheetForFilters, setAllsubmitedTimesheetForFilters] = useState([])
    const [allTimesheets, setAllTimesheets] = useState([])
    const [projectInfo, setProjectInfo] = useState([]);
    const [taskInfo, setTaskInfo] = useState([]);
    const [classesInfo, setClassesInfo] = useState([]);
    const [policy, setPolicy] = useState([]);
    const [allTheEmployee, setAllTheEmployee] = useState([])
    const [filters, setFilters] = useState({
        project: '',
        task: '',
        employee: '',
    });

    const [activeTab, setActiveTab] = useState("pendingApprovalTab")

    const [dateForFilter, setDateForFilter] = useState({ companyID: companyID })
    const handlechangeFilterDate = (e) => {
        const { name, value } = e.target;
        setDateForFilter((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }


    // get all project

    const getAllProject = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-project/?companyID=${companyID}`,
                config
            );
            setProjectInfo(data.projects);
        } catch (error) {
            console.log('error', error)
        }
    };

    // get all task

    const getAllTask = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-task/?companyID=${companyID}`,
                config
            );
            setTaskInfo(data.tasks);
        } catch (error) {
            console.log('error', error)
        }
    };

    const getAllClasses = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-active-classes/?companyID=${companyID}`,
                config
            );
            setClassesInfo(data.classes);
        } catch (error) {
            console.log('error', error)
        }
    };

    const getUserPolciy = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-policy-for-users/?userID=${userID}`,
                config
            );
            if (data) {
                setPolicy(data.allPolicy)
            }
        } catch (error) {
            console.log("error from get user timesheet api ", error);
        }
    };

    const handleChangeForFilterForUser = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    useEffect(() => {
        let filteredData = allsubmitedTimesheetForFilters;

        if (filters.project) {
            filteredData = filteredData.filter(timesheet => timesheet.project === filters.project);
        }

        if (filters.task) {
            filteredData = filteredData.filter(timesheet => timesheet.task === filters.task);
        }

        if (filters.employee) {
            filteredData = filteredData.filter(timesheet => timesheet.userName === filters.employee);
        }

        setAllsubmitedTimesheet(filteredData);
    }, [filters, allsubmitedTimesheetForFilters]);


    const handleSubmitFilterData = async (e) => {
        e.preventDefault();
        handleClose()
        let filteredData = allsubmitedTimesheetForFilters;


        if (dateForFilter.startDate && dateForFilter.endDate) {

            const finalData = filteredData.filter(item => {
                const itemDate = new Date(item.date);
                return itemDate >= new Date(dateForFilter.startDate) && itemDate <= new Date(dateForFilter.endDate);
            });

            setAllsubmitedTimesheet(finalData)

        }
    }


    const aproveTimesheet = async (itemID, TimesheetOwnerID) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You want to approve this Timesheet",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Approve it!"
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    if (adminloginInfo?.token) {
                        const { data } = await axios.get(`https://app.timetraker.com/backend/api/aprove-timesheet/?timesheetID=${itemID}`, config)
                        if (data.updatedTimesheet) {
                            successNotify('Timesheet has been approved')
                            // handleClose1()
                            setNewData(data)
                        }
                    } else {
                        const { data } = await axios.post(`https://app.timetraker.com/backend/api/aprove-timesheet-by-approver`, { timesheetID: itemID, userID: TimesheetOwnerID, approverID: userID }, config)
                        if (data.updatedTimesheet) {
                            successNotify('Timesheet has been approved')
                            // handleClose1()
                            setNewData(data)
                        }
                    }
                }
                catch (error) {
                    console.log("approveTimesheet error", error)
                }
            }
        })
    }


    const [rejectID, setRejectID] = useState("")
    const [userRejectID, setUserRejectID] = useState("")
    const [rejectReason, setRejectReason] = useState("")

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event, _id, timesheetOwner) => {
        setRejectID(_id)
        setUserRejectID(timesheetOwner)
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const handleChangeRejectReason = (e) => {
        setRejectReason(e.target.value)
    }

    const rejectTimesheet = async () => {
        handleCloseUserMenu()

        Swal.fire({
            title: "Are you sure?",
            text: "You want to reject this Timesheet",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Reject it!"
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    if (adminloginInfo?.token) {
                        const { data } = await axios.post(`https://app.timetraker.com/backend/api/reject-timesheet`,
                            { rejectID: rejectID, rejectReason: rejectReason },
                            config)
                        if (data.updatedTimesheet) {
                            successNotify('Timesheet has been rejected')
                            setNewData(data)
                        }
                    } else {
                        const { data } = await axios.post(`https://app.timetraker.com/backend/api/reject-timesheet-by-approver`,
                            { rejectID: rejectID, rejectReason: rejectReason, userID: userRejectID, approverID: userID },
                            config)
                        if (data.updatedTimesheet) {
                            successNotify('Timesheet has been rejected')
                            setNewData(data)
                        }
                    }
                }
                catch (error) {
                    console.log("approveTimesheet error", error)
                }
            }
        })
    }



    useEffect(() => {
        const getAllSubmitedTimesheet = async () => {

            try {
                const { data } = await axios.get(
                    `https://app.timetraker.com/backend/api/all-Timesheet/?companyID=${companyID}`,
                    config
                );


                const allTimesheets = data.timesheets.sort((a, b) => new Date(b.date) - new Date(a.date))



                if (activeTab === "downloadedTab") {

                    const downlodedData = allTimesheets.filter((item) => item.state == 'aproved' && item.sendToQb == true)
                    setAllsubmitedTimesheet(downlodedData)
                    setAllsubmitedTimesheetForFilters(downlodedData)
                }
                if (activeTab === "pendingDownloadTab") {
                    const pendingDownloadData = allTimesheets.filter((item) => item.state == 'aproved' && item.sendToQb == false)

                    console.log("pendingDownloadData", pendingDownloadData)
                    setAllsubmitedTimesheet(pendingDownloadData)
                    setAllsubmitedTimesheetForFilters(pendingDownloadData)
                }
                if (activeTab === "pendingApprovalTab") {
                    const pendingApprovalData = allTimesheets.filter((item) => item.state == 'submited')
                    setAllsubmitedTimesheet(pendingApprovalData)
                    setAllsubmitedTimesheetForFilters(pendingApprovalData)
                }
                if (activeTab === "pendingSubmissionTab") {
                    const pendingSubmissionData = allTimesheets.filter((item) => item.state == 'hold' || item.state == 'rejected')
                    setAllsubmitedTimesheet(pendingSubmissionData)
                    setAllsubmitedTimesheetForFilters(pendingSubmissionData)
                }

                setAllTimesheets(allTimesheets)


            } catch (error) {
                console.log("error from get timesheet api", error);
            }
        }

        const getAllTimesheetsByApprover = async () => {
            try {
                const { data } = await axios.get(
                    `https://app.timetraker.com/backend/api/all-Timesheet-for-approver/?approverID=${userID}`,
                    config
                );


                const allTimesheets = data.timesheets.sort((a, b) => new Date(b.date) - new Date(a.date))
                setAllTheEmployee(data.users)


                if (activeTab === "downloadedTab") {

                    const downlodedData = allTimesheets.filter((item) => item.state == 'aproved' && item.sendToQb == true)
                    setAllsubmitedTimesheet(downlodedData)
                    setAllsubmitedTimesheetForFilters(downlodedData)
                }
                if (activeTab === "pendingDownloadTab") {
                    const pendingDownloadData = allTimesheets.filter((item) => item.state == 'aproved' && item.sendToQb == false)

                    console.log("pendingDownloadData", pendingDownloadData)
                    setAllsubmitedTimesheet(pendingDownloadData)
                    setAllsubmitedTimesheetForFilters(pendingDownloadData)
                }
                if (activeTab === "pendingApprovalTab") {
                    const pendingApprovalData = allTimesheets.filter((item) => item.state == 'submited')
                    setAllsubmitedTimesheet(pendingApprovalData)
                    setAllsubmitedTimesheetForFilters(pendingApprovalData)
                }
                if (activeTab === "pendingSubmissionTab") {
                    const pendingSubmissionData = allTimesheets.filter((item) => item.state == 'hold' || item.state == 'rejected')
                    setAllsubmitedTimesheet(pendingSubmissionData)
                    setAllsubmitedTimesheetForFilters(pendingSubmissionData)
                }

                setAllTimesheets(allTimesheets)


            } catch (error) {
                console.log("error from get timesheet api", error);
            }
        }

        if (adminloginInfo?.token) {
            getAllSubmitedTimesheet()
        } else {
            getAllTimesheetsByApprover()
        }
    }, [newData])


    const [reversSort, setReversSort] = useState(false)

    const handleChangeSort = () => {
        setReversSort(prevToggle => !prevToggle);
    };


    useEffect(() => {
        if (reversSort) {
            const data = [...allsubmitedTimesheet].sort((a, b) => new Date(a.date) - new Date(b.date));
            setAllsubmitedTimesheet(data)
        } else {
            const data = [...allsubmitedTimesheet].sort((a, b) => new Date(b.date) - new Date(a.date));
            setAllsubmitedTimesheet(data)
        }
    }, [reversSort])


    const [selectedRowItems, setSelectedRowItems] = useState([])
    const [selectedRowIds, setSelectedRowIds] = useState([])

    const handleCheck = (item) => {
        setSelectedRowItems(prevselectedRowItems => {
            if (prevselectedRowItems.includes(item)) {
                return prevselectedRowItems.filter(item => item !== item);
            } else {
                return [...prevselectedRowItems, item];
            }
        });


        setSelectedRowIds(prevselectedRowIds => {
            if (prevselectedRowIds.includes(item._id)) {
                return prevselectedRowIds.filter(item => item !== item._id);
            } else {
                return [...prevselectedRowIds, item._id];
            }
        })
    };



    const [allCheck, setAllCheck] = useState(false)

    const handleChnageAllCheck = () => {
        if (allCheck === false) {
            setAllCheck(true)
            if (rowsPerPage > 0) {
                allsubmitedTimesheet?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                    setSelectedRowItems(prevselectedRowItems => {
                        return [...prevselectedRowItems, item];
                    })

                })
            }
        } else {
            setAllCheck(false)
            setSelectedRowItems([])
        }
    }

    const approveSelectedTimesheets = async () => {

        Swal.fire({
            title: "Are you sure?",
            text: "You want to approve these Timesheets",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Approve"
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    if (selectedRowItems.length < 1) {
                        errorNotify("Please select at least 1 Timesheet!!")
                    } else {

                        const rowIds = []

                        selectedRowItems.map((item) => {
                            rowIds.push(item._id)
                        })

                        if (rowIds) {
                            const { data } = await axios.post(
                                `https://app.timetraker.com/backend/api/approve-selected-timesheet`,
                                selectedRowItems,
                                config
                            );
                            if (data) {
                                setNewData(data);
                                successNotify('Timesheets has been approved!!')
                                setSelectedRowItems([])
                            }
                        }
                    }
                } catch (error) {
                    console.log("error", error)
                    errorNotify("Something went wrong!!")
                }
            }
        })
    }

    const reDownloadTimesheets = async () => {

        Swal.fire({
            title: "Are you sure?",
            text: "You want to re-download these Timesheets",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    if (selectedRowItems.length < 1) {
                        errorNotify("Please select at least 1 Timesheet!!")
                    } else {

                        const rowIds = []

                        selectedRowItems.map((item) => {
                            rowIds.push(item._id)
                        })

                        if (rowIds) {
                            const { data } = await axios.post(
                                `https://app.timetraker.com/backend/api/re-download-selected-timesheet`,
                                selectedRowItems,
                                config
                            );
                            if (data) {
                                setNewData(data);
                                successNotify('Timesheets has been sent to the approve tab!!')
                                setSelectedRowItems([])
                            }
                        }
                    }
                } catch (error) {
                    console.log("error", error)
                    errorNotify("Something went wrong!!")
                }
            }
        })
    }

    const getAllUser = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-users/?companyID=${companyID}`,
                config
            );
            allTheEmployee.push(...data.allUsers);
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };

    const getSuperAdmin = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/super-admin/?companyID=${companyID}`,
                config
            );
            allTheEmployee.push(...data.superAdmin);
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };

    const getAllManager = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-manager/?companyID=${companyID}`,
                config
            );
            allTheEmployee.push(...data.allManager);
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };

    const getAllSubAdmin = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-sub-admin/?companyID=${companyID}`,
                config
            );
            allTheEmployee.push(...data.allSubAdmin);
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };

    const submitTimesheet = async (itemID) => {
        handleCloseUserMenu()
        Swal.fire({
            title: "Are you sure?",
            text: "You want to submit this Timesheet",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, submit it!"
        }).then(async (result) => {
            if (result.isConfirmed) {


                try {
                    const { data } = await axios.get(`https://app.timetraker.com/backend/api/submit-timesheet/?timesheetID=${itemID}`, config)
                    setNewData(data);
                    successNotify('Timesheet has been submited!!')

                }
                catch (error) {
                    console.log("submitTimesheet error", error)
                    errorNotify("Something went wrong!!")
                }
            }
        });
    }

    const deleteData = async (itemID) => {
        handleCloseUserMenu()
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this Timesheet",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(`https://app.timetraker.com/backend/api/delete-timesheet/?timesheetID=${itemID}`, config)
                    setNewData(data);
                    successNotify('Timesheet has been deleted!!')

                } catch (error) {
                    console.log(error)
                    errorNotify("Something went wrong!!")
                }
            }
        })

    }

    const nitifyUser = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to notify employee",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    if (selectedRowItems.length < 1) {
                        errorNotify("Please select at least 1 Timesheet!!")
                    } else {
                        const { data } = await axios.post(`https://app.timetraker.com/backend/api/nitify-hold-Timesheets`, { selectedRowItems: selectedRowItems }, config)
                        successNotify('We have sent the nitification email!!')
                        setSelectedRowItems([])
                    }
                } catch (error) {
                    console.log(error)
                    errorNotify("Something went wrong!!")
                }

            }
        })
    }



    useEffect(() => {
        getAllTask()
        getAllProject()
        getAllClasses()
        getUserPolciy()
        if (adminloginInfo?.token) {
            getAllUser();
            getAllManager();
            getAllSubAdmin();
            getSuperAdmin();
        }
    }, [])


    /// quickbooks 

    const [dataFormQuick, setDataFormQuick] = useState("");


    const getQBTokenFormDataBase = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-qb-token?companyID=${companyID}`,
                config
            );
            if (data) {
                refresh_token_Access_token(data)
            }
        } catch (error) {
            setLoading(false)
            console.log("error from getAllBankAccount", error);

            // we have to send the notification if not connected
        }
    }


    const refresh_token_Access_token = async (oldData) => {

        setLoading(true)

        let newDataFormQuick = oldData.existToken.fullToken
        // decryptObjectForQB()
        const token = newDataFormQuick;
        try {
            const { data } = await axios.post(
                `https://app.timetraker.com/backend/refreshAccessToken`,
                { token: token, timetrakerCompanyID: companyID },
                config
            );
            if (data) {

                const access_token = data.result.token.access_token
                const refresh_token = data.result.token.refresh_token
                const id_token = data.result.token.id_token

                newDataFormQuick.token.access_token = access_token
                newDataFormQuick.token.refresh_token = refresh_token
                newDataFormQuick.token.id_token = id_token

                // localStorage.setItem("dataFormQuick", JSON.stringify(newDataFormQuick));
                encryptObjectForQB(newDataFormQuick)

                let refress = false
                saveTokenInBackend(newDataFormQuick, refress)
            }

        } catch (error) {
            // setLoading(false)
            console.log(error.response.data.result)
            if (error.response.data.result = "connectToQb") {
                deleteTokenFormMongo()
            }
        }
    }

    const deleteTokenFormMongo = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/delete-qb-token?companyID=${companyID}`,
                config
            );
            if (data) {
                localStorage.removeItem("dataFormQuick")
                window.location.reload()
            }
        } catch (error) {
            console.log(error)
        }
    }


    const saveTokenInBackend = async (dataFormQuick, refress) => {
        setLoading(true)
        decryptObjectForQB()


        try {
            const { data } = await axios.post(
                `https://app.timetraker.com/backend/api/update-qb-token?companyID=${companyID}`,
                dataFormQuick,
                config
            );
            setLoading(false)
            if (data && refress) {

                window.location.reload()
            }
        } catch (error) {
            setLoading(false)
            console.log("error from getAllBankAccount", error);
        }

    }

    const encryptionKey = 'YourEncryptionKey';

    const encryptObjectForQB = (objectToEncrypt) => {

        try {
            const encrypted = CryptoJS.AES.encrypt(JSON.stringify(objectToEncrypt), encryptionKey).toString();
            localStorage.setItem('dataFormQuick', encrypted);
        } catch (error) {
            console.error('Encryption failed:', error.message);
        }
    };

    const decryptObjectForQB = () => {
        const QbDataFromLocal = localStorage.getItem("dataFormQuick");
        if (QbDataFromLocal) {
            try {
                const bytes = CryptoJS.AES.decrypt(QbDataFromLocal, encryptionKey);
                setDataFormQuick(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            } catch (error) {
                console.error('Decryption failed:', error.message);
            }
        } else {
            console.log('No encrypted data found.');
        }
    };

    async function createSelectedTimesheet(e) {

        Swal.fire({
            title: "Are you sure?",
            text: "You want to download these Timesheets in QuickBooks",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Download"
        }).then(async (result) => {
            if (result.isConfirmed) {

                if (selectedRowItems.length < 1) {
                    errorNotify("Please select at least 1 Timesheet!!")
                } else {
                    setLoadingTimesheet(true)
                    decryptObjectForQB()
                    const token = dataFormQuick;
                    try {
                        let resp = await axios.post(
                            "https://app.timetraker.com/backend/create-selected-timesheet",
                            { token: token, timetrakerCompanyID: companyID, selectedRowItems: selectedRowItems },
                            config
                        );
                        setLoadingTimesheet(false)
                        setNewData(resp);
                        successNotify('Timesheets has been downloded!!')
                        setSelectedRowItems([])
                    } catch (error) {
                        setLoadingTimesheet(false)
                        alert("There is some error please connect with QuickBooks and try again");
                    }
                }
            }
        })
    }

    const initialStats = {
        submit: {
            count: 0,
            hour: 0
        },
        approve: {
            count: 0,
            hour: 0
        },
        hold: {
            count: 0,
            hour: 0
        },
        download: {
            count: 0,
            hour: 0
        },
        pending: {
            count: 0,
            hour: 0
        }
    }

    const [stats, setStats] = useState(initialStats);

    const handleGetAll = async () => {
        let dataArray = allTimesheets;

        setStats(initialStats)

        dataArray?.map((data) => {
            if (data.state === "submited") {
                setStats((prevData) => ({
                    ...prevData,
                    ["submit"]: {
                        count: prevData.submit.count + 1,
                        hour: prevData.submit.hour + data.hours
                    }
                }));
            }
            if (data.state === "aproved") {
                setStats((prevData) => ({
                    ...prevData,
                    ["approve"]: {
                        count: prevData.approve.count + 1,
                        hour: prevData.approve.hour + data.hours
                    }
                }));
            }
            if (data.state === "hold") {
                setStats((prevData) => ({
                    ...prevData,
                    ["hold"]: {
                        count: prevData.hold.count + 1,
                        hour: prevData.hold.hour + data.hours
                    }
                }));
            }
            if (data.state === "rejected") {
                setStats((prevData) => ({
                    ...prevData,
                    ["hold"]: {
                        count: prevData.hold.count + 1,
                        hour: prevData.hold.hour + data.hours
                    }
                }));
            }
            if (data.state === "aproved" && data.sendToQb === true) {
                setStats((prevData) => ({
                    ...prevData,
                    ["download"]: {
                        count: prevData.download.count + 1,
                        hour: prevData.download.hour + data.hours
                    }
                }));
            }
            if (data.state === "aproved" && data.sendToQb === false) {
                setStats((prevData) => ({
                    ...prevData,
                    ["pending"]: {
                        count: prevData.pending.count + 1,
                        hour: prevData.pending.hour + data.hours
                    }
                }));
            }

        })

    }



    useEffect(() => {
        handleGetAll()
    }, [allsubmitedTimesheet])

    useEffect(() => {
        if (adminloginInfo?.token) {
            getQBTokenFormDataBase()
        }
    }, [])




    const pendingSubmissionTab = () => {
        setActiveTab("pendingSubmissionTab")
        const pendingSubmissionData = allTimesheets.filter((item) => item.state == 'hold' || item.state == 'rejected')
        setAllsubmitedTimesheet(pendingSubmissionData)
    }

    const pendingApprovalTab = () => {
        setActiveTab("pendingApprovalTab")
        const pendingApprovalData = allTimesheets.filter((item) => item.state == 'submited')
        setAllsubmitedTimesheet(pendingApprovalData)
    }

    const pendingDownloadTab = () => {
        setActiveTab("pendingDownloadTab")
        const pendingDownloadData = allTimesheets.filter((item) => item.state == 'aproved' && item.sendToQb == false)
        setAllsubmitedTimesheet(pendingDownloadData)
    }

    const downloadedTab = () => {
        setActiveTab("downloadedTab")
        const downlodedData = allTimesheets.filter((item) => item.state == 'aproved' && item.sendToQb == true)
        setAllsubmitedTimesheet(downlodedData)
    }

    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
        if (selectedRowIds.length < 1) {
            errorNotify("Please select at least 1 Timesheet!!")
        } else {
            setOpen2(true);
        }
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const [open3, setOpen3] = React.useState(false);

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleClose3 = () => {
        setOpen3(false);
    };


    const [location, setLocation] = useState([])

    console.log("location", location)

    const [open4, setOpen4] = React.useState(false);

    const handleClickOpen4 = (updatelocation) => {
        if (!updatelocation?.latitude || !updatelocation?.longitude) {
            errorNotify("This timesheet does not include location details !!")
        } else {
            setLocation([
                { lat: updatelocation.latitude, lng: updatelocation.longitude }
            ])
            setOpen4(true);
        }
    };

    const handleClose4 = () => {
        setOpen4(false);
    };


    const [open5, setOpen5] = React.useState(false);

    const handleClickOpen5 = () => {

        if (selectedRowItems.length > 0) {

            const checkLocationAvailable = selectedRowItems.filter(item =>
                item?.location?.latitude &&
                item?.location?.longitude
            );

            if (checkLocationAvailable.length > 0) {
                checkLocationAvailable.map((item) => {
                    location.push({ lat: item.location.latitude, lng: item.location.longitude })
                })
                setOpen5(true);
            } else {
                errorNotify("Thes timesheets does not include location details !!")
            }
        } else {
            errorNotify("Please select at least 1 Timesheet !!")
        }

    };

    const handleClose5 = () => {
        setOpen5(false);
        setLocation([])
    };



    const [dataNeedToEdit, setDataNeedToEdit] = useState({ timeOff: false })
    const [selactedTask, setSelactedTask] = useState("")
    const [selactedClass, setSelactedClass] = useState("")
    const [selactedBillable, setSelactedBillable] = useState(true)
    const [selactedProject, setSelactedProject] = useState("")
    const [formData, setFormData] = useState({});

    const handleDailogBoxOpen = async (_id, hours, date, project, task, description, billable, timesheetOwnerID, timeOff, classDetails) => {
        setDataNeedToEdit({
            _id: _id,
            description: description,
            task: task,
            project: project,
            date: date,
            hours: hours,
            timeOff: timeOff,
            classDetails: classDetails
        })
        setFormData({
            description: description,
            task: task,
            project: project,
            date: date,
            hours: hours,
            userID: timesheetOwnerID,
            timeOff: timeOff,
            classDetails: classDetails
        })
        setSelactedTask(task)
        setSelactedClass(classDetails?._id)
        setSelactedProject(project)
        setSelactedBillable(billable)
        handleClickOpen3()
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleInputChangeTask = (e) => {
        setSelactedTask(e.target.value)
    }

    const handleInputChangeClass = (e) => {
        setSelactedClass(e.target.value)
    }


    const handleInputChangebillable = (e) => {
        setSelactedBillable(e.target.value)
    }

    const handleInputChangeProject = (e) => {
        setSelactedProject(e.target.value)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("formData", formData)
        if (formData.task == "" || formData.project == "" || formData.hours == "" || formData.date == "") {
            alert("all the feilds required");
        } else {
            postTimesheetData();
            // console.log("formData",formData)
        }
    };

    const postTimesheetData = async () => {
        try {
            const classData = classesInfo?.find(item => item._id === selactedClass)
            if (adminloginInfo?.token) {
                const { data } = await axios.post(
                    `https://app.timetraker.com/backend/api/edit-timesheet-by-admin`,
                    {
                        date: formData.date,
                        task: selactedTask,
                        project: selactedProject,
                        description: formData.description,
                        hours: formData.hours,
                        _id: dataNeedToEdit._id,
                        billable: selactedBillable,
                        classDetails: {
                            _id: classData?._id,
                            class: classData?.class,
                        }
                    },
                    config
                );
                handleClose3();
                handleCloseUserMenu()
                if (data) {
                    setNewData(data)
                    successNotify("Your timesheet has been successfully updated.");
                }
            } else {
                const { data } = await axios.post(
                    `https://app.timetraker.com/backend/api/edit-timesheet-by-approver`,
                    {
                        date: formData.date,
                        task: selactedTask,
                        project: selactedProject,
                        description: formData.description,
                        hours: formData.hours,
                        _id: dataNeedToEdit._id,
                        billable: selactedBillable,
                        userID: formData.userID,
                        approverID: userID,
                        classDetails: {
                            _id: classData?._id,
                            class: classData?.class,
                        }
                    },
                    config
                );
                handleClose3();
                handleCloseUserMenu()
                if (data) {
                    setNewData(data)
                    successNotify("Your timesheet has been successfully updated.");
                }

            }
        } catch (error) {
            console.log("error from post timesheet api", error);
            errorNotify("Something went Wrong!!")
        }
    };


    const successNotify = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const errorNotify = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    return (
        <div>
            {/* Row 1 */}
            {
                loading ?
                    <Loder className='loder' />
                    :
                    loadingTimesheet ?
                        <LoderForTimesheetDownload className='loder' />
                        :
                        console.log("")
            }
            <ToastContainer />




            <Row style={{ background: '#F1F1F1' }}>
                <Col md={12}>
                    <div className="card-container">

                        <div onClick={pendingSubmissionTab} className={activeTab === "pendingSubmissionTab" ? "card1 expense_card expense_card_sheet active_tab" : "card1 expense_card expense_card_sheet"} style={{ cursor: "pointer", borderRadius: "0px", width: "150px !important" }}  >
                            {/* <img src={cardImg4} alt="" /> */}
                            <div className='card_content_parent' style={{ flexDirection: "row", width: "100%", gap: "10px" }}>
                                <p className='card-para1'>{stats.hold.count}</p>
                                <div>
                                    <p className='card-para2' style={{ marginTop: "8px", fontSize: "16px", marginBottom: "0.1rem" }} >Pending Submission</p>
                                    <p style={{ fontSize: "12px" }} >{Math.round(stats.hold.hour)} Hours</p>
                                </div>
                            </div>
                        </div>

                        <div onClick={pendingApprovalTab} className={activeTab === "pendingApprovalTab" ? "card1 expense_card expense_card_sheet active_tab" : "card1 expense_card expense_card_sheet"} style={{ cursor: "pointer", borderRadius: "0px", width: "150px !important" }} >
                            {/* <img src={cardImg4} alt="" /> */}
                            <div className='card_content_parent' style={{ flexDirection: "row", width: "100%", gap: "10px" }}>
                                <p className='card-para1' >{stats.submit.count}</p>
                                <div>
                                    <p className='card-para2' style={{ marginTop: "8px", fontSize: "16px", marginBottom: "0.1rem" }} >Pending Approval</p>
                                    <p style={{ fontSize: "12px" }} >{Math.round(stats.submit.hour)} Hours</p>
                                </div>
                            </div>
                        </div>

                        {adminloginInfo?.token ?
                            <div onClick={pendingDownloadTab} className={activeTab === "pendingDownloadTab" ? "card1 expense_card expense_card_sheet active_tab" : "card1 expense_card expense_card_sheet"} style={{ cursor: "pointer", borderRadius: "0px", width: "150px !important" }} >
                                {/* <img src={cardImg4} alt="" /> */}
                                <div className='card_content_parent' style={{ flexDirection: "row", width: "100%", gap: "10px" }}>
                                    <p className='card-para1'>{stats.pending.count}</p>
                                    <div>
                                        <p className='card-para2' style={{ marginTop: "8px", fontSize: "16px", marginBottom: "0.1rem" }} >Pending Download</p>
                                        <p style={{ fontSize: "12px" }} >{Math.round(stats.pending.hour)} Hours</p>
                                    </div>
                                </div>
                            </div>

                            : null}


                        {adminloginInfo?.token ?
                            <div onClick={downloadedTab} className={activeTab === "downloadedTab" ? "card1 expense_card expense_card_sheet active_tab" : "card1 expense_card expense_card_sheet"} style={{ cursor: "pointer", borderRadius: "0px", width: "150px !important" }}  >
                                {/* <img src={cardImg4} alt="" /> */}
                                <div className='card_content_parent' style={{ flexDirection: "row", width: "100%", gap: "10px" }}>
                                    <p className='card-para1'>{stats.download.count}</p>
                                    <div>
                                        <p className='card-para2' style={{ marginTop: "8px", fontSize: "16px", marginBottom: "0.1rem" }} >Downloaded</p>
                                        <p style={{ fontSize: "12px" }} >{Math.round(stats.download.hour)} Hours</p>
                                    </div>
                                </div>
                            </div>
                            : null}



                    </div>
                </Col>
            </Row>




            {/* Row 2 */}
            <div className="adminlayout">
                <div className="mainContainer">



                    <div className="admin">
                        <div className="aproveFilterBy_parent">
                            <div className="text-time ">

                                <Form className="input-menu" >
                                    <Form.Select
                                        className="input-menu-focus"
                                        style={{ cursor: 'pointer' }}
                                        name="project"
                                        aria-label="Default select example"
                                        onChange={handleChangeForFilterForUser}

                                    >
                                        <option value="">Project</option>
                                        {projectInfo &&
                                            projectInfo.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.project}>
                                                        {item.project}
                                                    </option>
                                                );
                                            })}
                                    </Form.Select>

                                    <Form.Select
                                        className="input-menu-focus"
                                        style={{ cursor: 'pointer' }}
                                        name="task"
                                        aria-label="Default select example"
                                        onChange={handleChangeForFilterForUser}


                                    >
                                        <option value="">Task</option>
                                        {taskInfo &&
                                            taskInfo.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.task}>
                                                        {item.task}
                                                    </option>
                                                );
                                            })}
                                    </Form.Select>
                                    <Form.Select
                                        className="input-menu-focus"
                                        style={{ cursor: 'pointer' }}
                                        name="employee"
                                        aria-label="Default select example"
                                        onChange={handleChangeForFilterForUser}
                                    >
                                        <option value="">Employee</option>
                                        {allTheEmployee &&
                                            allTheEmployee.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.name}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                    </Form.Select>

                                    <i style={{ cursor: "pointer", fontSize: "29px", color: "#04542c", alignItems: "center", display: "flex" }} onClick={() => { handleClickOpen() }} class="fa-solid fa-calendar-days"></i>

                                </Form>
                            </div>


                            {activeTab === "pendingSubmissionTab"
                                ?
                                <div className="button-container">
                                    {TimesheetLocationTrack
                                        ?
                                        <LocationOnIcon onClick={handleClickOpen5} style={{ cursor: "pointer", fontSize: "37px", color: "#04542c" }} />
                                        : null}
                                    <button onClick={nitifyUser} className='button-container-button all_approve_button sendToQBButton' style={{ cursor: "pointer" }}>Notify Employee</button>
                                </div>
                                :
                                activeTab === "pendingApprovalTab"
                                    ?
                                    <div className="button-container">
                                        {TimesheetLocationTrack
                                            ?
                                            <LocationOnIcon onClick={handleClickOpen5} style={{ cursor: "pointer", fontSize: "37px", color: "#04542c" }} />
                                            : null}
                                        <button className='button-container-button all_approve_button' onClick={approveSelectedTimesheets} style={{ cursor: "pointer" }}>Approve</button>
                                        <button onClick={handleClickOpen2} className='button-container-button all_approve_button sendToQBButton' style={{ cursor: "pointer" }}>Print</button>
                                    </div>
                                    :
                                    activeTab === "pendingDownloadTab"
                                        ?
                                        <div className="button-container">
                                            {TimesheetLocationTrack
                                                ?
                                                <LocationOnIcon onClick={handleClickOpen5} style={{ cursor: "pointer", fontSize: "37px", color: "#04542c" }} />
                                                : null}
                                            <button className='button-container-button all_approve_button' onClick={handleClickOpen2} style={{ cursor: "pointer" }}>Print</button>
                                            {adminloginInfo?.token ?
                                                <button onClick={createSelectedTimesheet} className='button-container-button all_approve_button sendToQBButton' style={{ cursor: "pointer" }}>Send To QuickBooks</button>
                                                : null}
                                        </div>
                                        :
                                        activeTab === "downloadedTab"
                                            ?
                                            <div className="button-container">
                                                {TimesheetLocationTrack
                                                    ?
                                                    <LocationOnIcon onClick={handleClickOpen5} style={{ cursor: "pointer", fontSize: "37px", color: "#04542c" }} />
                                                    : null}

                                                <button onClick={handleClickOpen2} className='button-container-button all_approve_button ' style={{ cursor: "pointer" }}>Print</button>
                                                {adminloginInfo?.token ?
                                                    <button onClick={reDownloadTimesheets} className='button-container-button all_approve_button sendToQBButton' style={{ cursor: "pointer" }}>Re-Download</button>
                                                    : null}
                                            </div>
                                            :
                                            <div className="button-container">
                                                {TimesheetLocationTrack
                                                    ?
                                                    <LocationOnIcon onClick={handleClickOpen5} style={{ cursor: "pointer", fontSize: "37px", color: "#04542c" }} />
                                                    : null}

                                                <button className='button-container-button all_approve_button' onClick={approveSelectedTimesheets} style={{ cursor: "pointer" }}>Approve</button>
                                                <button onClick={createSelectedTimesheet} className='button-container-button all_approve_button sendToQBButton' style={{ cursor: "pointer" }}>Send To QuickBooks</button>
                                            </div>
                            }
                        </div>





                        <Paper sx={{ width: "100%", overflow: "auto" }}>
                            <TableContainer>
                                <Table
                                    stickyHeader
                                    aria-label="sticky table"
                                    style={{ overflow: "visible", cursor: "pointer" }}
                                >
                                    <TableHead sx={{ background: "#04542c" }}>
                                        <TableRow>
                                            {(TimesheetLocationTrack ? columnsForAllSubmitedTimesheetTableWithLocation : columnsForAllSubmitedTimesheetTable).map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        background: "#04542C",
                                                        color: "#fff",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {column.label === "Date" ?
                                                        <div onClick={handleChangeSort} style={{ display: "flex", cursor: "pointer" }}> <ArrowDropDownCircleIcon style={reversSort ? { transform: "rotate(180deg)", margin: "0px 10px 0px 17px" } : { margin: "0px 10px 0px 17px" }} /> {column.label} </div>
                                                        : column.label === "Check" ?
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                {column.label}
                                                                <Checkbox
                                                                    sx={{
                                                                        color: '#76ff03',
                                                                        '&.Mui-checked': {
                                                                            color: '#76ff03',
                                                                        },
                                                                    }}
                                                                    onChange={handleChnageAllCheck}
                                                                />
                                                            </div>
                                                            : column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        style={{ overflow: "hidden", cursor: "pointer" }}
                                    >
                                        {allsubmitedTimesheet
                                            ? (rowsPerPage > 0 ? allsubmitedTimesheet.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : allsubmitedTimesheet)?.map((item, index) => {

                                                function shortenString(str) {
                                                    if (str?.length > 8) {
                                                        return str.substring(0, 8) + '...';
                                                    }
                                                    return str;
                                                }
                                                function shortenString25(str) {
                                                    if (str.length > 25) {
                                                        return str.substring(0, 25) + '...';
                                                    }
                                                    return str;
                                                }
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                        <TableCell style={{ textAlign: 'center' }}>{item?.date}</TableCell>
                                                        <TableCell title={item?.userName} style={{ textAlign: 'center' }}>{shortenString(item?.userName)}</TableCell>
                                                        <TableCell style={{ textAlign: 'center' }}>{item?.hours}</TableCell>
                                                        <TableCell style={{ textAlign: 'center' }}>{item?.billable ? "Yes" : "No"}</TableCell>

                                                        <TableCell title={item?.task} style={{ textAlign: 'center' }}>{shortenString(item?.task)}</TableCell>
                                                        <TableCell title={item?.project} style={{ textAlign: 'center' }}>{item?.project ? shortenString(item?.project) : "Empty"}</TableCell>
                                                        <TableCell style={!additionalSettings.timesheetClasses ? { display: "none" } : null} title={item?.classDetails?.class} align="center">{shortenString(item?.classDetails?.class ? item?.classDetails?.class : "Empty")}</TableCell>
                                                        <TableCell title={item?.description ? item?.description : "Empty"} style={{ textAlign: 'center' }}>{shortenString(item?.description ? item?.description : "Empty")}</TableCell>


                                                        <TableCell align="center">
                                                            <Checkbox checked={selectedRowItems.includes(item)}
                                                                onChange={() => handleCheck(item)}

                                                                sx={{
                                                                    color: blue[800],
                                                                    '&.Mui-checked': {
                                                                        color: blue[600],
                                                                    },
                                                                }} />
                                                        </TableCell>


                                                        {activeTab === "pendingSubmissionTab" ?
                                                            <TableCell style={{ textAlign: 'center' }}>
                                                                <i
                                                                    title="Submit"
                                                                    onClick={() => submitTimesheet(item?._id)}
                                                                    style={{ color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer', marginRight: "10px" }}
                                                                    className="fa-solid fa-arrow-up-from-bracket userIconsHover"
                                                                ></i>
                                                                <i
                                                                    title="Delete"
                                                                    onClick={() => deleteData(item?._id)}
                                                                    style={{ color: 'red', fontSize: '20px', cursor: 'pointer', marginLeft: "10px" }}
                                                                    className="fa-sharp fa-solid fa-trash userIconsHover"
                                                                ></i>
                                                            </TableCell>
                                                            :
                                                            activeTab === "pendingDownloadTab" ?

                                                                <TableCell style={{ textAlign: 'center' }}>
                                                                    <i
                                                                        title="Edit"
                                                                        onClick={() => handleDailogBoxOpen(
                                                                            item?._id,
                                                                            item?.hours,
                                                                            item?.date,
                                                                            item?.project,
                                                                            item?.task,
                                                                            item?.description,
                                                                            item?.billable,
                                                                            item?.userID,
                                                                            item?.timeOff,
                                                                            item?.classDetails,
                                                                        )

                                                                        }
                                                                        style={{ color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }}
                                                                        className="fa-solid fa-pen-to-square userIconsHover"
                                                                    ></i>
                                                                    <i
                                                                        onClick={(event) => (handleOpenUserMenu(event, item?._id, item?.userID))}
                                                                        class="fa-solid fa-thumbs-down"
                                                                        title='Reject'
                                                                        style={{ color: 'red', fontSize: '20px', marginLeft: "10px" }}>
                                                                    </i>
                                                                </TableCell>
                                                                :
                                                                activeTab === "downloadedTab" ?
                                                                    <TableCell style={{ textAlign: 'center' }}>
                                                                        <i
                                                                            class="fa-solid fa-thumbs-up"
                                                                            title={item?.state === 'aproved' ? 'null' : 'Approve'}
                                                                            style={
                                                                                item?.state === 'aproved' ?
                                                                                    { color: 'gray', fontSize: '20px', marginRight: "10px" }
                                                                                    :
                                                                                    { color: '#73c31d', fontSize: '20px', marginRight: "10px" }
                                                                            }>
                                                                        </i>
                                                                        <i
                                                                            class="fa-solid fa-thumbs-down"
                                                                            title={item?.state === 'aproved' ? 'null' : 'Reject'}
                                                                            style={
                                                                                item?.state === 'aproved' ?
                                                                                    { color: 'gray', fontSize: '20px', marginleft: "10px" }
                                                                                    :
                                                                                    { color: 'red', fontSize: '20px', marginLeft: "10px" }
                                                                            }>
                                                                        </i>
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell style={{ textAlign: 'center' }}>
                                                                        <i
                                                                            onClick={() => (item?.state === 'aproved' ? null : aproveTimesheet(item?._id, item?.userID))}
                                                                            class="fa-solid fa-thumbs-up"
                                                                            title={item?.state === 'aproved' ? 'null' : 'Approve'}
                                                                            style={
                                                                                item?.state === 'aproved' ?
                                                                                    { color: 'gray', fontSize: '20px', marginRight: "10px" }
                                                                                    :
                                                                                    { color: '#73c31d', fontSize: '20px', marginRight: "10px" }
                                                                            }>
                                                                        </i>
                                                                        <i
                                                                            onClick={(event) => (item?.state === 'aproved' && item.sendToQb === true ? null : handleOpenUserMenu(event, item?._id, item?.userID))}
                                                                            class="fa-solid fa-thumbs-down"
                                                                            title={item?.state === 'aproved' && item.sendToQb === true ? 'null' : 'Reject'}
                                                                            style={
                                                                                item?.state === 'aproved' && item.sendToQb === true ?
                                                                                    { color: 'gray', fontSize: '20px', marginleft: "10px" }
                                                                                    :
                                                                                    { color: 'red', fontSize: '20px', marginLeft: "10px" }
                                                                            }>
                                                                        </i>
                                                                    </TableCell>
                                                        }




                                                        <Dialog
                                                            open={open4}
                                                            maxWidth="sm" fullWidth
                                                            onClose={handleClose4}
                                                        >
                                                            <MapComponent locations={location} />
                                                        </Dialog>


                                                        <Menu
                                                            sx={{ mt: '45px' }}
                                                            id="menu-appbar"
                                                            anchorEl={anchorElUser}
                                                            anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                            open={Boolean(anchorElUser)}
                                                            onClose={handleCloseUserMenu}
                                                        >
                                                            <div className="reject_reson" style={{ flexDirection: "column" }}  >

                                                                <p>Please add the reason of rejection!!</p>
                                                                <textarea onChange={handleChangeRejectReason} />
                                                                <button onClick={rejectTimesheet}>save</button>

                                                            </div>
                                                        </Menu>
                                                        <TableCell style={{ textAlign: 'center' }}>
                                                            {item?.state == 'aproved' && item.sendToQb == false ? 'Pending Download' : item?.state == 'aproved' && item.sendToQb == true ? 'Downloaded' : item?.state}
                                                        </TableCell>

                                                        {TimesheetLocationTrack
                                                            ?
                                                            <TableCell title={item?.location?.address ? item?.location?.address : "null"} onClick={() => handleClickOpen4(item.location)} style={{ textAlign: 'center' }} >
                                                                {shortenString25(item?.location?.address ? item?.location?.address : "null")}
                                                            </TableCell>
                                                            : null}
                                                    </TableRow>
                                                );
                                            })
                                            : data?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                    >
                                                        <TableCell align="center">{item?.name}</TableCell>
                                                        <TableCell align="center">{item?.email}</TableCell>
                                                        <TableCell align="center">{item?.phone}</TableCell>
                                                        <TableCell align="center">{item?.role}</TableCell>
                                                        <TableCell align="center">
                                                            {item?.createdAt}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                component="div"
                                count={allsubmitedTimesheet?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>


                </div>
            </div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <form style={{ marginTop: "20px", gap: "20px" }} onSubmit={handleSubmitFilterData} className='date_filter_for_cahrt'>
                    <label>Start</label>
                    <input required onChange={handlechangeFilterDate} name='startDate' type='date' />
                    <label>End</label>
                    <input required onChange={handlechangeFilterDate} name='endDate' type='date' />
                    <button type='submit' className=''>Filter</button>
                </form>
            </Dialog>

            <Dialog
                open={open2}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose2}
                aria-describedby="alert-dialog-slide-description"
            >
                <div style={{ padding: "50px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h4 style={{ marginBottom: "40px" }}>Please choose the format</h4>
                    <div style={{ marginBottom: "40px" }} className="button-container">
                        <UserPdf userTimesheetInfo={allTimesheets} selectedRowIds={selectedRowIds} />
                        <UserExcel userTimesheetInfo={allTimesheets} selectedRowIds={selectedRowIds} />
                        <UserCsv userTimesheetInfo={allTimesheets} selectedRowIds={selectedRowIds} />
                    </div>
                    <div className="button-container">
                        <button className='button-container-button' onClick={handleClose2} style={{ cursor: "pointer", background: "rgb(100, 228, 142)", }}>Close</button>
                    </div>
                </div>
            </Dialog>







            <Dialog
                open={open3}
                keepMounted
                onClose={handleClose3}
                aria-describedby="alert-dialog-slide-description"
            >
                <form
                    style={{ width: "600px", height: "500px" }}
                    className="dialog-main"
                    onSubmit={handleSubmit}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        <DialogActions>
                            <Button
                                style={{

                                    background: "rgb(100, 228, 142)",
                                    borderRadius: "10px",
                                    color: "#000",
                                    fontWeight: "600",
                                    padding: "5px 15px",
                                }}
                                onClick={handleClose3}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </div>

                    <div className="border-around">
                        <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "flex" }} className="form-time1 ">
                            <div style={{ width: "50%", display: 'flex', flexDirection: 'column', gap: '5px' }} className="customer">
                                <label className="lable_bold" htmlFor="">
                                    Hours
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <input
                                    style={dataNeedToEdit.timeOff === true ? { height: '40px', background: "#e8ebee" } : { height: '40px' }}
                                    name="hours"
                                    required
                                    defaultValue={dataNeedToEdit?.hours}
                                    onChange={handleInputChange}
                                    type="number"
                                    disabled={dataNeedToEdit.timeOff === true ? true : false}
                                />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: "50%" }} className="task">
                                <label className="lable_bold" htmlFor="">

                                    Date
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <input
                                    style={{ fontFamily: 'Inter', fontSize: '15px', height: '40px' }}
                                    defaultValue={dataNeedToEdit?.date}
                                    name="date"
                                    type="date"
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "flex" }} className="form-time1 ">
                            <div style={{ width: "50%" }} className="customer">
                                <label className="lable_bold" htmlFor="">
                                    Customer:Project
                                </label>
                                <Form.Select
                                    style={{ cursor: "pointer" }}
                                    value={selactedProject}
                                    className="area"
                                    name="project"
                                    aria-label="Default select example"

                                    onChange={handleInputChangeProject}
                                    disabled={dataNeedToEdit.timeOff === true ? true : false}
                                >
                                    <option value="">Select</option>
                                    {projectInfo &&
                                        projectInfo.map((item, index) => {
                                            return (
                                                <option key={index} value={item.project}>
                                                    {item.project}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </div>

                            <div style={{ width: "50%" }} className="task">
                                <label className="lable_bold" htmlFor="">
                                    Task
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <Form.Select
                                    style={{ cursor: "pointer" }}
                                    value={selactedTask}
                                    className="area"
                                    name="task"
                                    aria-label="Default select example"
                                    required
                                    onChange={handleInputChangeTask}
                                >
                                    <option value="">Select</option>
                                    {dataNeedToEdit.timeOff ? policy &&
                                        policy.map((item, index) => {
                                            return (
                                                <option key={index} value={item?.policyDetails?.policyName}>
                                                    {item?.policyDetails?.policyName}
                                                </option>
                                            );
                                        }) :
                                        taskInfo.map((item, index) => {
                                            return (
                                                <option key={index} value={item.name}>
                                                    {item.task}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </div>
                        </div>







                        <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "flex" }} className="form-time1 ">

                            <div style={additionalSettings.timesheetClasses === true ? { width: "50%" } : { display: "none" }} className="task">
                                <label className="lable_bold" htmlFor="">
                                    Class
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <Form.Select
                                    style={{ cursor: "pointer" }}
                                    value={selactedClass}
                                    className="area"
                                    name="classDetails"
                                    aria-label="Default select example"
                                    required={additionalSettings?.timesheetClasses ? additionalSettings?.timesheetClasses : false}
                                    onChange={handleInputChangeClass}
                                >
                                    <option value="">Select</option>

                                    {classesInfo &&
                                        classesInfo.map((item, index) => {
                                            return (
                                                <option key={index} value={item._id}>
                                                    {item.class}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </div>

                            <div style={additionalSettings.timesheetClasses === true ? { display: "none" } : { width: "50%", marginTop: "3px" }} className="customer">
                                <label className="lable_bold" htmlFor="">
                                    Description
                                </label>
                                <textarea defaultValue={formData?.description ? formData?.description : ""} className="edit_textarea" onChange={handleInputChange} type="text" name="description" id="">
                                </textarea>
                            </div>



                            <div style={{ width: "50%" }} className="task">
                                <label className="lable_bold" htmlFor="">
                                    Billable
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <Form.Select
                                    style={{ cursor: "pointer" }}
                                    value={selactedBillable}
                                    className="area"
                                    name="billable"
                                    aria-label="Default select example"
                                    required
                                    onChange={handleInputChangebillable}
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </Form.Select>
                            </div>
                        </div>

                        <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "none" }} className="form-time1 ">
                            <div style={{ width: "100%", marginTop: "3px" }} className="customer">
                                <label className="lable_bold" htmlFor="">
                                    Description
                                </label>
                                <textarea defaultValue={formData?.description ? formData?.description : ""} className="edit_textarea" onChange={handleInputChange} type="text" name="description" id="">
                                </textarea>
                            </div>
                        </div>
                    </div>


                    <div style={{ marginTop: "15px" }} className="strong">
                        <button type="submit" style={{ border: "none", background: "white" }}>
                            <strong style={{ display: "flex" }}>
                                Save
                            </strong>
                        </button>
                    </div>
                </form >
            </Dialog >



            <Dialog
                open={open5}
                maxWidth="sm" fullWidth
                onClose={handleClose5}
            >
                <MapComponent locations={location} />
            </Dialog>

        </div >
    );
};

export default TimesheetAndTimeOffApprove;
