import React, { useContext, useEffect, useState } from "react";
import "../AddUser.css";
import { Col, Form, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AdminLayout from "../../../hoc/AdminLayout";
import { MyContext } from "../../../context/MyProvider";
import { ToastContainer, toast } from 'react-toastify';
import ProfileComponent from "../../../components/ProfileComponent";
import Checkbox from '@mui/material/Checkbox';
import { blue } from '@mui/material/colors';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import axios from "axios";
import ClassIcon from '@mui/icons-material/Class';

const TimesheetSettings = () => {
    const navigate = useNavigate()
    const { sideBarStatus, setSideBarStatus, additionalSettings, setAdditionalSettings } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }



    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));


    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }



    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const [settings, setSettings] = useState({
        timer: false,
        clockIn: false,
        approver: false,
        classes: false,
    })
    const [newData, setNewData] = useState()

    const getSettings = async () => {
        try {
            const { data } = await axios.get(`https://app.timetraker.com/backend/api/get-settings/?companyID=${companyID}`, config)
            if (data) {
                setSettings({
                    timer: data.settings.timesheetSettings.timer,
                    clockIn: data.settings.timesheetSettings.clockIn,
                    approver: data.settings.timesheetSettings.approver,
                    classes: data.settings.timesheetSettings.classes,
                })

                setAdditionalSettings({
                    timer: data.settings.timesheetSettings.timer,
                    clockIn: data.settings.timesheetSettings.clockIn,
                    timesheetApprover: data.settings.timesheetSettings.approver,
                    timesheetClasses: data.settings.timesheetSettings.classes,
                    expenseApprover: data.settings.expenseSettings.approver,
                    receipt: data.settings.expenseSettings.receipt,
                })
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const updateSettings = async () => {
        try {
            const { data } = await axios.post(`https://app.timetraker.com/backend/api/update-settings/?companyID=${companyID}`,
                {
                    timer: settings.timer,
                    clockIn: settings.clockIn,
                    approverForTimeshees: settings.approver,
                    classesForTimeshees: settings.classes,
                    page: "timesheetSettings",
                    companyID: companyID
                },
                config)
            if (data) {
                setNewData(data)
                successNotify("Timesheet settings has been updated!!")
            }
        } catch (error) {
            console.log("error", error)
            errorNotify("Something went wrong!!")
        }
    }

    useEffect(() => {
        getSettings()
    }, [newData])

    const successNotify = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const errorNotify = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    return (
        <AdminLayout>
            < ToastContainer />
            <div
                className={sideBarStatus ? "content_right_dashboard" : "none"}
                fluid
                style={
                    (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                        ? { display: "none" }
                        : { display: "block" }
                }
            >
                <div
                    className="hamburgar"
                    style={sideBarStatus ? { display: "none" } : { display: "block" }}
                >
                    <i onClick={handleClick} className="fas fa-bars"></i>
                </div>
                <Container fluid className="dash3" style={{ padding: "0" }}>
                    {/* Row 1 */}
                    <Row>
                        <Col md={12} className="adduser-container">
                            <div className="adduser-top">
                                <div className="adduser-header1">
                                    <h5
                                        style={
                                            sideBarStatus == true
                                                ? { paddingLeft: "10px", paddingTop: "2px" }
                                                : { paddingLeft: "60px", paddingTop: "4px" }
                                        }
                                    >
                                        Timesheet Settings
                                    </h5>
                                </div>

                                <div className="adduser-header2">
                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ background: "#F1F1F1" }} className="mt-0">
                        <Col>
                            <div
                                className="time"
                                style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    marginLeft: "84px"
                                }}
                            >

                                <div className="text-time">
                                    <p
                                        style={{ cursor: "pointer", marginBottom: "0px" }}
                                        className="para1"
                                        onClick={() => {
                                            navigate("/time-off-policy");
                                        }}
                                    >
                                        Time Off
                                    </p>
                                    <p
                                        style={{ cursor: "pointer", marginBottom: "0px" }}
                                        className="para1"
                                        onClick={() => {
                                            navigate("/holiday");
                                        }}
                                    >
                                        Holiday
                                    </p>
                                    <p
                                        style={{ backgroundColor: "#64E48E", cursor: "pointer", marginBottom: "0px" }}
                                        className="para1"

                                    >
                                        Time Settings
                                    </p>

                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="step_wizard_body">
                        <h2>Extra Features to Elevate Your Time Tracking.</h2>
                        <p>Choose from the below options. We will customize your account accordingly.</p>

                        <div className="cards">

                            <div className="cards2">

                                <div className="step_card">
                                    <AccessTimeFilledIcon style={{ fontSize: "40px", color: "#04542c" }} />
                                    <p>Timer</p>
                                    <Checkbox
                                        defaultChecked={settings.timer ? settings.timer : false}
                                        checked={settings.timer ? settings.timer : false}
                                        onChange={() => {
                                            setSettings((previousData) => ({
                                                ...previousData,
                                                timer: !settings.timer,
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>

                                <div className="step_card">
                                    <PunchClockIcon
                                        style={{
                                            fontSize: "40px",
                                            color: "rgb(4, 84, 44)"
                                        }}
                                    />
                                    <p>Clock In</p>
                                    <Checkbox
                                        defaultChecked={settings.clockIn ? settings.clockIn : false}
                                        checked={settings.clockIn ? settings.clockIn : false}
                                        onChange={() => {
                                            setSettings((previousData) => ({
                                                ...previousData,
                                                clockIn: !settings.clockIn,
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>

                                <div className="step_card">
                                    <i style={{ fontSize: "40px", color: "#04542c" }} class="fa-solid fa-user"></i>
                                    <p>Approvers</p>
                                    <Checkbox
                                        defaultChecked={settings.approver ? settings.approver : false}
                                        checked={settings.approver ? settings.approver : false}
                                        onChange={() => {
                                            setSettings((previousData) => ({
                                                ...previousData,
                                                approver: !settings.approver,
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>

                                <div className="step_card">
                                 <ClassIcon style={{ fontSize: "40px", color: "#04542c" }} />
                                    <p>Classes</p>
                                    <Checkbox
                                        defaultChecked={settings.classes ? settings.classes : false}
                                        checked={settings.classes ? settings.classes : false}
                                        onChange={() => {
                                            setSettings((previousData) => ({
                                                ...previousData,
                                                classes: !settings.classes,
                                            }))
                                        }}
                                        sx={{
                                            color: blue[800],
                                            '&.Mui-checked': {
                                                color: blue[600],
                                            },
                                        }} />
                                </div>
                            </div>

                            <div onClick={updateSettings} style={{ justifyContent: "start" }} className="button">
                                <button >Save</button>
                            </div>
                        </div>
                    </Row>




                </Container>
            </div>

        </AdminLayout>
    );
};

export default TimesheetSettings;
